@import "iconfont.css";
$color-border: #E9E9E9;
$color-background: #F4F4F4;
$color-primary: #5D98F0;
/*
$color-primary: #6772e4;
 */

html,body {
    min-width:1200px;
    min-height:100vh;
}
body {
    &.role-tenement{
        background-image: linear-gradient(to right, #536d9d, #768ec7);
    }
    &.role-viewer{
        /*
        background-image: linear-gradient(90deg,#3cae75, #6b88b8);
         */
background-image: linear-gradient(90deg,#ae6f8e, #6b88b8);
    }
    /*
    background-color: #2485CC;
    background-image: linear-gradient(90deg,#577cae, #6b88b8);
    background-image: linear-gradient(90deg,#4553FF, #10BEFF);
    background-image: linear-gradient(217deg, rgba(57, 179, 255, 0.8), rgba(100, 82, 255, 0) 70.71%), linear-gradient(127deg, rgba(5, 27, 255, 0.8), rgba(198, 250, 255, 0) 70.71%);
     */
background: url(../images/bg.png) no-repeat;
background-position: top left;
background-size: 100% 230px;
margin: 0;
font: 14px/1.5 Arial, "Microsoft Yahei";
display: flex;
flex-direction: column;
color:#566573;
overflow-y:scroll;
}
a{
    color:$color-primary;
}

.mr10{
    margin-right: 10px;
}
.mt20{
    margin-top: 20px;
}
.btn,.form-control{
    font-size: 14px;
}
.btn{
    cursor: pointer;
    &[disabled]{
        cursor:not-allowed;
        filter: grayscale(100%);
    }
}


.main-header {
    background-color: #2C3E50;
    background-color: transparent;
    border-bottom:1px solid rgba(0,0,0,0.1);
    color:white;
    display: flex;
    align-items:center;
    padding: 5px 15px;
    box-shadow:inset 0 -1px 0 0 rgba(255,255,255,.2);
    min-height:41px;
    h1{
        span{
            font-family: Arial;
            /*
            color:#E53A30;
             */
font-weight: bold;
font-size: 26px;
line-height: 1;
vertical-align:-2px;
margin-right: 3px;
        }
        margin: 0;
        font-size: 20px;
    }
    .logo{
        width:100px;
    }
    .status{
        flex:1;
        .inner{
            display: inline-block;
            /*
            background-color: #475669;
            border:1px solid #556370;
            background-color: rgba(0,0,0,0.3);
            border:1px solid rgba(255,255,255,.2);
             */
padding:5px 10px;
border-radius: 3px;
margin-left: 10px;
span{
    color:#ececec;
}
a{
    color:#CCC;
    margin-left: 15px;
    &:hover{
        color:#FFF;
        text-decoration: none;
    }
}
        }
    }
    aside{
        margin-left: auto;
        a{
            color:#F0F0F0;
            &:hover{
                color:white;
            }
            text-decoration: none;
            margin-left: 10px;
            &:last-child{
                border-left:1px solid #CCC;
                padding-left: 10px;
            }
        }
    }
}
.main-nav{
    background-color: #425262;
    background-color: transparent;
    display: flex;
    justify-content:space-between;
    /*
    border-bottom:5px solid rgba(0,0,0,0.3);
     */
&>span{
    display: flex;
    &:last-child{
        border-left:1px solid rgba(0,0,0,0.1);
        box-shadow:-1px 0 0 0 rgba(255,255,255,.3);
    }
}
a{
    min-width:80px;
    color:#EEE;
    font-size: 14px;
    text-decoration: none;
    position: relative;
    display: flex;
    flex-direction:column;
    align-items:center;
    margin-right: 1px;
    /*
    border-right:1px solid #35424E;
     */
border-right:1px solid rgba(0,0,0,0.1);
box-shadow:1px 0 0 0 rgba(255,255,255,.1);
padding:6px 8px 6px;
.iconfont{
    font-size: 25px;
    margin-bottom: 5px;
}
&.special{
    /*
    background-color:  #3f608a!important;
     */
background-color: rgba(27, 68, 113, 0.7);
color:#EEE;
&:hover{
    background-color: rgba(27, 68, 113, 0.7);
    color:#EEE;
}
        }
        &.active{
            color:white;
            /*
            background-color: #2C3E50;
            background-color: darken(#425262, 5%);
             */
background-color: rgba(0,0,0,.3);
&:hover{
    color:white;
    background-color: rgba(0,0,0,.2);
    /*
    background-color: darken(#425262, 5%);
     */
}
        }
        &:hover{
            color:rgba(255,255,255,.8);
            /*
            background-color: lighten(#425262, 1%);
             */
background-color: rgba(0,0,0,.1);
        }
    }
}

.main-section{
    flex:1;
    display: flex;
    >.container-fluid{
        flex:1;
        display: flex;
        padding: 0;
    }
    .main-row{
        display: flex;
        width: 100%;
        flex:1;
    }
    .content-row{
        flex:1;
        position: relative;
        z-index: 1;
    }

}
.main-row>.main-side-nav{
  background-color: #fff;
  display: flex;
  flex-direction:column;
  border-right:1px solid #e9e9e9;
  >.cell{
    display: flex;
    flex-direction: column;
    justify-content:center;
    &:first-child{
      border-bottom:1px solid #e9e9e9;
    }
    &:nth-child(2){
      margin-top: auto;
    }
  >a{
    padding:10px 13px;
    color:grey;
    text-decoration: none;
    &.active{
      color:#6772e4;
      &:hover{
        color:#6772e4;
      }
    }
    &:hover{
      color:darken(grey,10%);
    }
  }
}
  .iconfont{
    font-size: 24px;
  }
}

.main-row>.nav-row{
    border-right:1px solid $color-border;
    background-color: #F9f9f9;
    width: 200px;
    .nav-side{
        list-style:none;
        padding-left: 0;
        margin: 0;
        flex-direction: column;
        .nav-link{
            padding: 15px 15px;
            display: block;
            border-bottom:1px solid #dbdbdb;
            border-radius:0;
            color:inherit;
            text-decoration: none;
            .iconfont{
                display: none;
            }
            &:hover{
                color: #555;
            }
            &.active{
                background-color: #fff;
                color:$color-primary;
            }
            &:before{
                content: "";
                width: 5px;
                height: 5px;
                background-color: currentColor;
                opacity:0.5;
                display: inline-block;
                vertical-align:3px;
                margin-right: 3px;
            }
        }
    }
}

.main-row>.content-row{
    background-color: #fff;
    .breadcrumb{
        display: flex;
        border-bottom: 1px solid $color-border;
        border-radius: 0;
        padding: 15px 15px;
        background-color: $color-background;
        margin-bottom: 0;
        em{
            flex:1;
            font-style: normal;
            font-size: 14px;
        }
    }
    .sub-header{
        border-bottom:1px solid $color-border;
        padding:0 15px;
        margin-bottom: 10px;
        display: flex;
        align-items:center;
        min-height: 52px;
        h2{
            font-size: 18px;
            flex:1;
            display: flex;
            align-items:center;
            margin-bottom: 0;
            color:#2D3E4F;
            font-weight: bold;
            .small{
                font-size: 14px;
                font-weight: normal;
                color: #666;
                margin-left: 10px;
                border-left:1px solid currentColor;
                padding-left: 10px;
            }
        }
        .opt{
        }

    }
    .padding-wrapper{
        padding:0 15px 15px;
        >.nav-tab{
            margin: -10px -15px 10px;
        }
    }
}



.links-wrapper{
    a{
        margin-right: 10px;
        &:last-child{
            margin-right: 0px;
        }
    }
}
.buttons-wrapper{
    >.btn{
        margin-right: 10px;
        &:last-child{
            margin-right: 0;
        }
    }
    >.btn-group{
        margin-right: 10px;
    }
}
.has-required .col-form-label:before{
    content: "*";
    color:red;
    margin-right: 5px;
    display: inline-block;
    vertical-align: -2px;
}

.help-block{
    font-size: 12px;
    margin: 5px 0 0 0;
}

.has-danger .help-block{
    color:#D9534F;
}

.col-form-label{
    text-align: right;
    &:after{
        content: ":";
        margin-right: -5px;
        padding-left: 5px;
    }
    &.label-empty:after{
        content:none;
    }
}

.custom-control{
    margin-bottom: 0;
    min-height: 14px;
    margin-top: 3px;
    .custom-control-input{
        visibility: hidden;
        &:focus{
            outline-width:0;
        }
        &[disabled]~.custom-control-indicator{
            border-color: #CCC;
            cursor: not-allowed !important;
        }
    }
    .custom-control-indicator{
        top: 0;
    }
    .custom-control-description{
        margin-top: 1px;
        line-height: 14px;
    }
}

.custom-control{
}
.custom-checkbox .custom-control-input:disabled:checked~.custom-control-indicator{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#AAA' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    cursor:not-allowed !important;
}
.custom-radio .custom-control-input:disabled:checked~.custom-control-indicator{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#AAA'/%3E%3C/svg%3E")
}



.label-wrapper{
    /*
    display: flex;
    justify-content: space-between;
     */
margin-top: 8px;
label{
    /*
    min-width:230px;
     */
margin-right: 8px;
padding-left: 20px;
}
}

.custom-control{
    margin-right: 0;
}

.flex-inline{
    display: flex;
}

.label-opt-wrapper{
    margin-top: 30px;
    .form-inline{
        margin: 20px 0;
    }
    .form-group{
        width: 100%;
        display: flex;
        .form-control{
            flex:1;
        }
        .opt{
            margin-left: 5px;
            min-width:40px;
        }
    }
}

.form-group{
    margin-bottom: 20px;
}

.toolbar{
    margin: 0px 0 10px;
    &.toolbar-bg{
        /*
        background-color: #F4F4F4;
        padding:10px;
         */
border-bottom:1px dashed #D9D9D9;
padding-bottom: 10px;
    }
    &.form-inline{
        select,input,button{
            margin-right: 10px;
        }
    }
    .form{
        flex:1
    }
    .filter{
        &>span{
            margin-right: 15px;
            &:last-child{
                margin-right: 0;
            }
        }
        a{
            color:gray;
            &:hover,&.active{
                text-decoration: none;
                color:#333;
            }
        }
        .badge{
            vertical-align: middle;
        }
    }
}


.toolbar-table{
    /*
    border:1px solid $color-border;
    border-bottom:none;
     */
margin: 0px 0 10px;
.active{
    background-color: #fff;
    border-color: #1477D5;
    color: #1477D5;
    cursor: default;
}
}
.toolbar-tips{
    border:1px solid $color-border;
    border-bottom-width:0;
    background-color:rgba(255, 255, 0, 0.26);;
    text-align: center;
    padding:3px 0;
}

.table-custom{
    thead>tr>th{
        background-color: $color-background;
        vertical-align: middle !important;
        border-right-color:#e1e1e1;
    }
    tbody>tr>th{
        vertical-align: middle !important;
        padding-top: 0;
        padding-bottom: 0;
        height: 40px;
    }
    tbody>tr>td{
        vertical-align: middle;
        border-right:none;
        padding-top: 0;
        padding-bottom: 0;
        height: 50px;
    }
    time,.small{
        font-size: 12px;
        span{
            display: block;
        }
    }
    tbody>tr:hover td[rowspan]{
        background-color: white;
    }
    tr.active,tr.active:hover{
        background-color: #ffffc7;
    }
    .form-control{
        padding-top: 4px;
        padding-bottom: 4px;
    }
    select.form-control{
        height: 25px !important;
        line-height: 25px !important;
        padding-top: 0px !important;
    }


    .align-top{
        vertical-align: top;
        padding-top: 20px;
    }
    .caption-top{
        caption-side: top;
    }
    .tr-collapse{
        border-top:1px solid red;
        td{
            padding: 10px;
        }
        &:hover{
            background-color: transparent;
        }
        .table-custom{
            margin-bottom: 0;
        }
    }
}

.table-hover tbody tr:hover{
    background-color: rgba(0,0,0,0.03);
}


.table-bordered{
    tbody>tr>td{
        border-left:1px solid #ECEEEF;
    }
}
.table-nohover{
    tbody tr:hover{
        background-color: transparent;
    }
}

.table tbody+tbody{
    border-top-width:1px;
}

.table-modal-add{
    border-left:none !important;
    .form-control[type="number"]{
        display: inline-block;
        width: 65px;
        text-align:right;
    }
    tbody:after{
        content:"";
        display:table-row;
        border:none;
        height: 15px;
        margin-left: -3px;
        position: relative;
        left:-10px;
    }
    tbody:last-child:after{
        content:none;
    }
}


.table-sticky-custom{
    thead{
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        z-index: 3;
    }
}

.menu-wrapper{
    display: flex;
    margin-top: -10px;
    .nav-row{

        border-right:1px solid $color-border;
        .nav-side{
            list-style:none;
            padding-left: 0;
            margin: 0;
            margin-bottom: 15px;
            flex-direction: column;
            min-width: 165px;
            &.nav-collapse{
                /*
                min-width: 240px;
                 */
.ul-collapse{
    margin-left: 10px;
    margin-bottom: 15px;
    li{
        &>a{
            color:inherit;
        }
        padding:5px 0;
        &.active{
            list-style-type: disc;
        }
        &.active,&.active>a{
            color: $color-primary;
        }
    }
}
.nav-link::before{
    left: 25px;
    top: 20px;
    transition:all 500ms;
    transform:rotate(90deg);
    transform-origin: 0% 25%;
}
.nav-link.collapsed::before{
    left:20px;
    transform:rotate(0deg);
}
            }
            .nav-item{
                border-bottom:1px solid $color-border;
            }
            .nav-link{
                padding: 15px 15px;
                display: block;
                color:inherit;
                text-decoration: none;
                padding-left: 30px;
                position: relative;
                border-radius:0;
                .iconfont{
                    float: right;
                    font-size: 20px;
                    margin-top: -2px;
                }
                &:before{
                    content:"";
                    position: absolute;
                    top: calc(50% - 2px);
                    transform: translate(-100%,-50%);
                    width: 0;
                    height: 0px;
                    border-width:5px;
                    border-style: solid;
                    border-color: transparent transparent transparent currentColor;
                }
                &.active{
                    background-color: $color-background;
                    color:$color-primary;
                }
            }
        }
    }
}


.icon-done{
    color:#2ECC71;
}
.icon-close{
    color: #E74C3C;
}

.confirm-wrapper{
    background-color: #f4f4f4;
    padding:15px;
    margin: 20px 0;
    textarea{
        flex:1;
    }
}

.nav-tab{
    border-bottom:1px solid $color-border;
    .nav-item{
    }
    .nav-link{
        color:inherit;
        position: relative;
        padding: 15px 20px;
        &.active{
            color:$color-primary;
            background-color: transparent;
            &:after{
                content:"";
                width: 10px;
                height: 10px;
                position: absolute;
                bottom:0;
                left: 50%;
                transform: translate(-50%, 50%) rotate(-45deg);
                background-color: white;
                border:1px solid;
                border-color: $color-border $color-border transparent transparent;

            }
        }
    }
}


.card{
    border:none;
    .card-block{
        background-color: $color-background;
    }
    .card-title{
        font-size: 18px !important;
        margin-bottom: 0;
    }
    .iconfont{
        font-size: 40px;
        vertical-align: middle;
        margin-right: 10px;
    }
}
.iconfont{
    font-size: inherit;
}

.icon-success,
.icon-success{
    color: green;
}
.icon-failed,
.icon-error{
    color: rgb(229, 47, 63);
}
.icon-alert,
.icon-alert{
    color: rgb(255, 184, 83);
}

.modal{
    .modal-body{
        max-height: 75vh;
        overflow: auto;
        min-width: 500px;
    }
    .modal-header{
        padding: 10px 15px;
        background-color: #3A82FC;
        color:white;
        .close{
            color:white;
            text-shadow: none;
        }

    }
    .modal-title{
        font-size:16px;
    }
    .modal-footer{
    }
    .desc{
        width: 80%;
        margin: auto;
        text-align:justify;

    }

    /*
     * modal align center
     * */
text-align: center;
&:before{
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
}
.modal-dialog{
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}
}
.modal-lg{
    width: 800px;
}


.initial{
    span{
        color:$color-primary;
        margin: 0 5px 5px 0;
        border:1px solid $color-primary;
        text-align: center;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        display: inline-block;
        font-weight: bold;
        cursor: pointer;
        &.active{
            background-color: $color-primary;
            color:white;
            cursor: default;
        }
    }
}
.selector{
    display: flex;
    flex-wrap:wrap;
    max-height: 190px;
    overflow:auto;
    span{
        border:1px dashed $color-primary;
        padding: 5px 10px;
        display: inline-block;
        margin: 0 10px 10px 0px;
        width: 23%;
        cursor: pointer;
        &:nth-child(4n){
            margin-right: 0;
        }
        em{
            display: block;
            font-style: normal;
        }
    }
}



.date-setting{
    /*
    width:590px !important;
     */
border:1px solid $color-border;
padding:10px;
.cell{
    display: flex;
    border-top:1px solid $color-border;
    padding-top: 15px;
    &:first-child{
        border-top:none;
    }
    .label{
        white-space:nowrap;
        margin-right: 20px;
        min-width:5em;
        text-align: right;
        font-weight: bold;
    }
    .content{
        flex:1;
        .config{
            padding-bottom: 15px;
            border-bottom:1px dashed $color-border;
            .custom-control{
                width:125px;
            }
        }
        .form-inline{
            margin: 15px 0;
            input[type="date"]{
                width: 260px;
            }
            .input-time{
                margin: 0 10px;
                width: 110px !important;
            }
        }
    }
}
}

.icon-plus,.icon-minus{
    font-size: 20px;
    cursor: pointer;
}
.icon-plus{
    color:#2ECC71;
    &:hover{
        color:darken(#2ECC71, 10%);
    }
}
.icon-minus{
    color:#FF5E4C;
    &:hover{
        color:darken(#FF5E4C, 10%);
    }
}

.modal-body .padding-v{
    padding: 10px 0;
}


.label-opt-wrapper{
    margin-top: 30px;
    .form-inline{
        margin: 20px 0;
    }
    .form-group{
        width: 100%;
        display: flex;
        .form-control{
            flex:1;
        }
        .opt{
            margin-left: 5px;
            min-width:40px;
        }
    }
}

.form-group{
    margin-bottom: 20px;
}


/*
 * some bugs fixed for bootstrap v3 plugin
 * bootstrap-datetimepicker
 */


@font-face {
    font-family: 'Glyphicons Halflings';

    src: url('../fonts/glyphicons-halflings-regular.eot');
    src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('../fonts/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}
.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.glyphicon-th:before {
    content: "\e011";
}
.glyphicon-remove:before {
    content: "\e014";
}
.glyphicon-arrow-left:before {
    content: "\e091";
}
.glyphicon-arrow-right:before {
    content: "\e092";
}


.glyphicon-trash:before {
    content: "\e020";
}
.glyphicon-upload:before {
    content: "\e027";
}
.glyphicon-folder-open:before {
    content: "\e118";
}
.glyphicon-file:before {
    content: "\e022";
}
.glyphicon-zoom-in:before {
    content: "\e015";
}
.glyphicon-resize-vertical:before {
    content: "\e119";
}
.glyphicon-fullscreen:before {
    content: "\e140";
}
.glyphicon-resize-full:before {
    content: "\e096";
}
.glyphicon-remove:before {
    content: "\e014";
}
.glyphicon-ban-circle:before {
    content: "\e090";
}
.glyphicon-hand-down:before {
    content: "\e130";
}



.dropdown-toggle::after{
    position:absolute;
    right:7px;
    top:50%;
    transform: translateY(-50%);
}

.dropdown-menu{
    font-size:14px;
    margin-top:8px;
    .dropdown-item{
        padding: 3px 1rem;
    }
}

.multiselect-native-select .btn-default{
    background-color: #fff;
    border:1px solid #CCC;
    padding-left: 10px;
    outline:0;
}
.multiselect-container>li>a>label{
    color:#333;
    padding-left: 16px !important;
}
.multiselect-container>li.active{
    background-color: #0275D8;
}
.multiselect-container>li.active>a>label{
    color:white;
}


.select2-container .select2-selection--single .select2-selection__rendered{
    padding-left: 10px !important;
}

.city-picker-span{
    padding-left: 5px;
}



.select2-container .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__arrow
{
    height: 35px !important;
}
.select2-container--default .select2-selection--single .select2-selection__rendered{
    line-height: 35px !important;
}

.sub-title{
    border-bottom:1px solid $color-border;
    padding-bottom:10px;
    margin-bottom:20px;
    display: inline-flex;
    align-items:center;
    width: 100%;
    min-height: 46px;
    .opt,.handle{
        margin-left: auto;
    }
    padding-bottom: 0;
    h5{
        font-size: 16px;
        margin-bottom: 0;
    }
}



.col-10.single-column{
    max-width: 900px;
}



.pagination{
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
}
.pagination>li {
    display: inline;
}


.pagination>li>a, .pagination>li>span{
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
}


.pagination>li:first-child>a, .pagination>li:first-child>span{
    margin-left: 0;
}

.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover{
    z-index: 2;
    color: #23527c;
    background-color: #eee;
    border-color: #ddd;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover{
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #5A98DE;
    border-color: #5A98DE;
}
.nav-pagination .pagination{
    /* margin-right: 20px; */
}
.nav-pagination .btn-sm{
    min-width: auto;
}
.nav-pagination .input-text{
    width: 68px;
}
.nav-pagination {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    //justify-content: flex-end;
}
.nav-pagination .jump{
    -webkit-box-flex:1;
    -ms-flex:1;
    flex:1;
}


.data-empty{
    text-align: center;
    padding: 100px 0;
    .iconfont{
        font-size: 120px;
        color:#CCC;
    }
    p{
        color:#AAA;
        margin-top: -20px;
    }
}

.input-file{
    display:flex;
    position: relative;
    input[type="text"]{
        border-top-right-radius:0;
        border-bottom-right-radius:0;
    }
    input[type="file"]{
        position: absolute;
        right: 0;
        width: 90px;
        height: 100%;
        opacity:0;
        cursor: pointer;
    }
    button{
        border-top-left-radius:0;
        border-bottom-left-radius:0;
    }
}


.input-image{
    border:1px dashed #CCC;
    display: inline-block;
    position: relative;
    display: flex;
    justify-content: center;
    align-items:center;
    &:hover{
        border-color:#1FB922;
    }
    &:hover:after{
        color:#1FB922;
    }
    &:after{
        position: absolute;
        z-index: 0;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        left:0;
        text-align: center;
        content: "\e60c";
        font-family: "iconfont" !important;
        font-size: 60px;
        color: gray;
    }
    &>img{
        max-width: 100%;
        max-height: 100%;
        position: relative;
        z-index: 1;
    }
    input[type="file"]{
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity:0;
        cursor: pointer;
    }
}

select.form-control:not([size]):not([multiple]){
    height: 35px;
    /*
    line-height: 35px;
     */
padding-top: 5px;
}

.form-control{
    max-width:460px;
}

input.form-control[readonly]{
    background-color: #fff !important;
}

.col-suffix{
    position: relative;
    display: inline-block;
    width:460px;
    >.suffix{
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(calc(100% + 10px), 50%);
    }
    >select{
        margin-bottom: 20px;
    }
}

.menu-wrapper{
    .single-column{
        .form-group>label{
            width:13em;
            flex-basis:13em;
        }
        .offset-sm-3{
            margin-left: 13em;
        }
    }
}

@media (min-width: 1200px){
    .col-6>.form-group>label.col-4{
        max-width: 13em;
    }
}


.form-control::placeholder{
    color:#a2a2a2;
}
.form-control.text-size-5{
    width: 120px;
}
.form-control.text-size-10{
    width: 200px;
}

.custom-control-indicator{
    border:1px solid #0275D8;
    background-color: #fff;
}


.content-row{
    display: flex;
    flex-direction:column;
    &>.padding-wrapper{
        padding-top: 8px !important;
    }
    &>.content{
        display: flex;
        flex:1;
        flex-direction:column;
        &>.padding-wrapper{
            flex:1;
            display: flex;
            flex-direction:column;
            &>.container-fluid{
                width:100%;
            }
        }
        &>.menu-wrapper{
            flex:1;
        }
    }
}

button, input, optgroup, select, textarea{
    font-family: inherit;
}

.modal .selector-wrapper{
    min-width:auto;
    display: flex;
    .cell{
        flex:1;
    }
    .handle{
        width:40px;
    }
    .border-wrapper{
        height: 460px;
    }
}
.selector-wrapper{
    margin-top:5px;
    display:flex;
    min-width: 800px;
    .toolbar-table .form-control{
        width: 180px;
    }
    .handle{
        margin-top:130px;
        display:flex;
        align-items:center;
        justify-content: center;
        flex-direction: column;
        .btn{
            display:block;
            margin:10px;
        }
    }
    .table>tbody>tr>td{
        width:120px;
        &:nth-child(1){
            padding-right:0px;
            width:0;
        }
    }
    .border-wrapper{
        border:1px solid #ECEEEF;
        /*
        min-height:128px;
         */
height:330px;
overflow:auto;
.table-bordered{
    margin-bottom: 0;
}
    }
}



.mb20{
    margin-bottom: 20px;
}
.mtb20{
    margin-top: 20px;
    margin-bottom: 20px;
}

.text-right{
    text-align: right;
}
.dashboard{
    border-bottom:1px solid $color-border;
    margin-bottom: 15px;
    /*
    display:grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap:30px 50px;
     */
&:after{
    content: "";
    display:block;
    clear:both;
}   
a{
    color:inherit;
    &:hover{
        color:$color-primary;
        text-decoration: none;
        .text{
            color:inherit;
        }
    }
}
.cell{
    border:1px solid $color-border;
    background-color: #fff;
    border-left:4px solid #CCC;
    padding:10px 0 10px 20px;
    display: inline-block;
    width: 19.2%;
    margin-right: 1%;
    margin-bottom: 15px;
    float:left;
    &:nth-child(5n){
        margin-right: 0;
    }
    .text{
        font-size: 40px;
        line-height: 1;
        color:#666;
    }
    &:nth-child(1){
        border-left-color:#8aa4cc;
    }
    &:nth-child(2){
        border-left-color:#a8cc70;
    }
    &:nth-child(3){
        border-left-color:#b690cc;
    }
    &:nth-child(4){
        border-left-color:#6cccc6;
    }
    &:nth-child(5){
        border-left-color:#b6ccb3;
    }
    &:nth-child(6){
        border-left-color:#cca874;
    }
    &:nth-child(7){
        border-left-color:#cc6aa1;
    }
    &:nth-child(8){
        border-left-color:#ddc5c7;
    }
    &:nth-child(9){
        border-left-color:#8688cc;
    }
    &:nth-child(10){
        border-left-color:#adbdc6;
    }
}
&.more{
}
}


/*
.dashboard{
padding: 20px 0 30px;
border-bottom:1px solid $color-border;
margin-bottom: 20px;
display:grid;
grid-template-columns: repeat(5, 1fr);
grid-template-rows: repeat(6, 1fr);
grid-gap:20px;
.cell{
display: flex;
text-align: center;
border:1px solid $color-border;
.icon,.content{
}
.icon{
background-color: #ECF0F1;
width: 60px;
display: flex;
align-items:center;
justify-content:center;
.iconfont{
font-size: 40px;
}
}
.content{
display:flex;
flex:1;
flex-direction:column;
justify-content:center;
text-align: left;
padding-left: 10px;
.text{
font-size: 40px;
line-height: 1;
font-weight: bold;
}
}
}
&.more{
}
}
 */


.form-apply{
    display: flex;
    width:100%;
    margin-top: 20px;
    flex-direction:row-reverse;
    .handle{
        .user-avatar{
            width: 160px;
            height: 200px;
            margin-bottom: 20px;
            background: #EFEFEF url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyBjbGFzcz0iaWNvbiIgd2lkdGg9IjIwMHB4IiBoZWlnaHQ9IjIwMC4wMHB4IiB2aWV3Qm94PSIwIDAgMTAyNCAxMDI0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTY2NS42IDY1Ni41NjVjMTA1LjQxMi01NC4yMTIgMTc3LjY5NC0xNjguNjYgMTc3LjY5NC0yOTUuMTUzIDAtMTgzLjcxOC0xNDcuNTc2LTMzNC4zMDYtMzMxLjI5NC0zMzQuMzA2UzE4MC43MDYgMTc0LjY4MiAxODAuNzA2IDM1OC40YzAgMTI2LjQ5NCA3Mi4yODIgMjQzLjk1MyAxNzcuNjk0IDI5OC4xNjUtMTc0LjY4MiA1MS4yLTI5OC4xNjUgMTk1Ljc2NC0yOTguMTY1IDM0Ni4zNTNoNjAuMjM2QzEyMC40NyA4MjIuMjEyIDI5NS4xNTMgNzAxLjc0IDUxMiA3MDEuNzRzMzkxLjUzIDEyMC40NyAzOTEuNTMgMzAxLjE3N2g2MC4yMzVjMC0xNTAuNTg5LTEyMy40ODMtMjk1LjE1My0yOTguMTY1LTM0Ni4zNTN6TTI0MC45NDEgMzU4LjRjMC0xNTAuNTg4IDEyMC40Ny0yNzEuMDU5IDI3MS4wNTktMjcxLjA1OVM3ODMuMDU5IDIwNy44MTEgNzgzLjA1OSAzNTguNCA2NjIuNTg5IDYyOS40NTkgNTEyIDYyOS40NTkgMjQwLjk0MSA1MDUuOTc2IDI0MC45NDEgMzU4LjR6IiBmaWxsPSIjYmZiZmJmIiAvPjwvc3ZnPg==) no-repeat center center;
            background-size: 90%;
        }
        .buttons-wrapper{
            display: flex;
            padding: 0 30px;
            justify-content: space-around;
        }
    }
    .form{
        flex:1;
    }
}

.form-apply .col-form-label{
    width: 118px !important;
    flex:none;
    max-width: auto !important;
}
.form-apply-more{
    .row>div:first-child .col-form-label{
        width: 118px !important;
        flex:none;
        max-width: auto !important;
    }
}

.form-apply-text{
    .form{
        margin-right: 20px;
        .table-custom {
            th,td{
                text-align: center;
            }
            td{
                height: 40px;
            }
            th{
                vertical-align: middle;
            }
        }
    }
}

.table-text-center{
    th,td{
        text-align: center;
    }
}
.guage{
    display: flex;
    margin: 10px 0;
    &>span{
        padding:10px;
        flex:1;
        text-align: center;
        background-color: #EEE;
        position: relative;
        clip-path:polygon(0 0, 95% 0, 100% 50%, 95% 100%, 0 100%, 5% 50%);
        &:first-child{
            clip-path:polygon(0 0, 95% 0, 100% 50%, 95% 100%, 0 100%, 0 0);
        }
        &:last-child{
            clip-path:polygon(0 0, 100% 0, 100% 100%,  0 100%, 5% 50%);
        }
        &.active{
            background-color: #75b76c;
            color:white;
        }
    }
}

.table-text-wrapper{
    background-color: #f9f9f9;
    padding:5px 10px;
    .table-custom th,
    .table-custom td
    {
        background-color: #fff;
        text-align: center;
    }
    .table-custom th{
        vertical-align: middle;
        border-bottom-width:1px;
    }
    .table-bordered td{
        border:1px solid $color-border;
    }
}
.hr-dash{
    border-top-style: dashed;
}

.circle-outline{
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    border:1px solid #999;
    border-radius: 100%;
}

.table-has-danger-off{
    .has-danger .form-control{
        border-color:#D9D9D9;
        &:focus{
            border-color:#5cb3fd;
        }
    }
}


.layer-single-line{
    font-size: 18px;
    .iconfont{
        font-size: 40px;
        vertical-align: middle;
    }
}

.layer-select-country{
    padding: 0;
}
.layer-select-city{
    .label-wrapper{
        .custom-checkbox{
            width:30%;
            min-width:auto;
            margin: 0 10px 10px 0;
        }
    }
}

.layer-check{
    margin-top: 20px;
}
.layer-multiple-line{
    width:80%;
    margin:10px auto;
    display: flex;
    .icon{
        .iconfont{
            font-size: 40px;
            line-height: 1;
        }
    }
    .content{
        flex:1;
        margin-left: 10px;
        h4{
            font-size: 20px;
        }
        p{
            margin-bottom: 3px;
        }
    }
}


.nav-tabs-custom{
    margin:0px 0 10px;
    position: relative;
    .active{
        font-weight: bold;
        border-color:#DDD !important;
        border-bottom-color:transparent !important;
    }
    .nav-item{
        margin-right: 10px;
        .nav-link{
            border-color:#ECEEEF;
            border-bottom-color:#DDD;
            padding-top:10px;
            padding-bottom: 10px;
        }
    }
    .right-handle{
        position: absolute;
        right:0;
        line-height: 37px;
    }
}

.status-panel{
    &.status-alert{
        background-color: #f4f4f4;
        padding:1.25rem;
        margin-bottom: 15px;
    }
    display: flex;
    .icon{
        margin-top: 2px;
        .iconfont{
            font-size: 40px;
            line-height: 1;
        }
    }
    .content{
        align-self:center;
        margin-left: 1.25rem;
        ul,ol{
            margin-bottom: 0;
            padding-left:16px;
        }
    }
}



/*编排*/
.link-back{
    color:#CCC;
    font-size: 18px;
    &:hover,&:focus{
        color:white;
        text-decoration: none;
    }
}

.division{
    span{
        padding-left: 20px;
        margin-left: 20px;
        display: inline-block;
        color:#EEE;
        border-left:1px solid rgba(255,255,255,.3);
        &:first-child{
            margin-left: 0;
            border-left:0;
        }
    }
}

.special{
    .nav-row{
        h4{
            font-size: 16px;
            padding-left: 25px;
            font-weight: bold;
            margin: 17px 0;
        }
        ul+h4{
            margin-top: 40px;
        }
        .nav-side{
            border-top:1px solid #dbdbdb;
        }
    }
    .form-date{
        width: 300px;
    }
    .bottom-line{
        border-bottom:1px solid #DDD;
        padding-bottom: 10px;
    }
    .nav-tabs-flex{
        margin-bottom: 10px;
        .nav-item{
            flex:1;
            text-align: center;
            font-weight: bold;
            .nav-link{
                background-color: #FFF;
                color:inherit;
                border-radius:0;
                padding-top: 8px;
                padding-bottom: 9px;
                border-color:#DDD;
                &:hover{
                    /*
                    border-color:transparent;
                     */
                }
                &.active{
                    background-color: #85c1e9;
                    color:white;
                    &:hover{
                        /*
                        border-color: #DDD #DDD transparent;
                         */
cursor:default;
                    }
                }
            }
        }
    }
}



.div-line{
    height: 1px;
    background-color: #E9E9E9;
    border:none;
    margin: 15px 0;
}
.plan-wrapper{
    .cell{
        display: inline-block;
        width: 220px;
        height: 120px;
        text-align: center;
        margin:20px 0px 20px 15px;
        color:white;
        vertical-align: top;
        cursor: pointer;
        opacity:0.9;
        &:hover{
            opacity:1;
            text-decoration: none;
        }
        .iconfont{
            font-size: 40px;
            display: block;
            margin-top: 20px;
        }
        &:nth-child(1){
            background-color: #3FA19D;
        }
        &:nth-child(2){
            background-color: #55A782;
        }
        &:nth-child(3){
            background-color: #68A5C2;
        }
        &:nth-child(4){
            background-color: #D77A51;
        }
    }
}
.nav-row{
    position: relative;
    transition:all 0.5s;
    &.hide{
        margin-left: -205px;
        .arrow:before{
            transform:translate(-50%, -50%) rotate(45deg);
        }
    }
    .arrow{
        position: absolute;
        top:300px;
        right:0;
        transform:translate(100%, -50%);
        content:"";
        display: block;
        width: 15px;
        height: 50px;
        background-color: #d9d9d9;
        clip-path: polygon(0% 0%, 100% 20%, 100% 80%, 0% 100%);
        z-index: 1;

        &:hover{
            background-color: #2786F3;
            cursor: pointer;
            &:before{
                border-color: white;
                border-left-color: transparent;
                border-bottom-color: transparent;
            }
        }
        &:before{
            position: absolute;
            content:"";
            width: 10px;
            height: 10px;
            top:50%;
            right:0;
            transform:translate(0%, -50%) rotate(-135deg);
            z-index: 2;
            border:2px solid white;
            border-left-color: transparent;
            border-bottom-color: transparent;
        }
    }
}

.form-result{
    .col-form-label{
        max-width: 120px;
    }
    &.form-text{
        .form-group{
            margin-bottom: 0;
        }
        .text-list{
            width:800px;
            .cell{
                width: 24%;
                display: inline-block;
                vertical-align: top;
                margin-bottom: 15px;
            }
        }
    }
    .label-wrapper{
        label{
            min-width:150px;
        }
    }
    .form-adjust{
        .form-inline{
            white-space:nowrap;
            width:700px;
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
            .handle{
                margin-left: 50px;
            }
        }
        .custom-radio{
            min-width: 120px;
            text-align: left;
            justify-content: left;
        }
        input.form-control{
            width:80px;
            margin: 0 10px;
        }
    }
}

/*
.dl-list{
display: flex;
max-width:1000px;
padding-left: 45px;
margin-bottom: 0;
dt{
width:120px;
}
dd{
flex:1;
display: flex;
flex-wrap:wrap;
.cell{
width: 25%;
margin-bottom: 10px;
}
}
}
 */



.setting-wrapper{
    background-color: #F4F4F4;
    padding: 10px;
    >p{
        border-bottom:1px solid #DDD;
        padding-bottom: 10px;
    }
    .cell{
        display: inline-block;
        width:33%;
        text-align: center;
        border-left:1px solid #DDD;
        padding:20px 0;
        &:first-child{
            border-left:0;
        }
    }
}


/*
$color-type-a: #93221f;
$color-type-b: #087013;
$color-type-c: #276daa;
$color-type-d: #b76c2a;
 */
$color-type-a: #564178;
$color-type-b: #116030;
$color-type-c: #134A9F;
$color-type-d: #a72e46;

.type-1{
    .main-header{
        background-color: darken($color-type-a, 5%);
    }
    .main-nav{
        background-color: $color-type-a;
        border-bottom-color:$color-type-a;
        a{
            color:#EEE;
        }
    }
}
.type-2{
    .main-header{
        background-color: darken($color-type-b, 5%);
    }
    .main-nav{
        background-color: $color-type-b;
        a{
            color:#EEE;
        }
    }
}
.type-3{
    .main-header{
        background-color: darken($color-type-c, 5%);
    }
    .main-nav{
        background-color: $color-type-c;
        a{
            color:#EEE;
        }
    }

}
.type-4{
    .main-header{
        background-color: darken($color-type-d, 5%);
    }
    .main-nav{
        background-color: $color-type-d;
        a{
            color:#EEE;
        }
    }
}


.index-layout{
    display: flex;
    margin-top: 20px;
    .table-no-border{
        margin-top: -12px;
    }
    .col-6>.cell{
        background-color: #F5F8FB;
        padding:15px 15px;
    }
    .col-6>.cell+.cell{
        margin-top: 20px;
    }
    .column-double{
        flex:1;
    }
    .table-list {
        margin-bottom: 3px;
        a{
            position: relative;
            padding-left: 10px;
            color:#555;
            display: block;
            &:hover{
                color:#5d98f0;
            }
            &:before{
                position: absolute;
                content:"";
                width: 6px;
                height: 6px;
                left:0;
                top:calc(50% - 3px);
                background-color: #CCC;
            }
        }
        tr:first-child td{
            border-top:none;
        }
        td{
            height: 35px;
        }
    }
    .sub-title{
        margin-bottom: 0;
        border-bottom:1px solid #DEDEDE;
        margin-bottom: 15px;
        min-height:auto;
        padding-bottom: 15px;
    }
    .dashboard{
        border:none;
        margin-bottom: 5px;
        display: flex;
    }
    .dashboard>.cell{
        flex:1;
        background-color: #fff;
        border:none;
        border-top:10px solid;
        vertical-align:top;
        text-align: center;
        padding:20px 20px;
        margin: 0;
        &+.cell{
            margin-left: 15px;
            margin-right:0;
        }
        &:nth-child(1){
            border-top-color:#009ED6;
        }
        &:nth-child(2){
            border-top-color:#32AB9F;
        }
        &:nth-child(3){
            border-top-color:#FF9900;
        }
    }
}


.help-center-index{
    display: flex;
    .cell{
        margin-bottom: 5px;
        &:hover{
            cursor: pointer;
            p{
                color:#609AED;
            }
        }
        &+.cell{
            margin-left: 20px;
        }
        text-align: center;
        background-color: #fff;
        padding:20px 0;
        flex:1;
        .iconfont{
            font-size: 50px;
            color:#609AED;
        }
        p{
            margin-bottom: 0;
        }
    }
}

.ul-plan{
    list-style-type:none;
    padding-left: 0;
    li{
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
        max-width:300px;
        margin-bottom: 24px;
    }
    a{
        color:inherit;
        &:hover{
            color:#639CEA;
        }
    }
}

.table-no-border{
    border:none;
    td, th{
        border:none;
    }
}

.login-page{
    &:after{
        content:"";
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        background-color: rgba(0,0,0,0.2);
        z-index: 1;
    }
    background: url(../images/bg.jpg) no-repeat top center;
    background-size: cover;
    .main-header{
        border:none;
        box-shadow:none;
        position: relative;
        z-index: 2;
    }
    .login-footer{
        position: absolute;
        z-index: 2;
        bottom:0;
        width: 100%;
        text-align: center;
        color: rgba(255,255,255,.7);
        text-shadow:0 0 10px rgba(0,0,0,0.5);
        a{
            color:white;
        }
        p{
            margin: 5px 0;
            font-size: 12px;
        }
    }
}

.login-body{
    background-size: 100% 300px;
    display: flex;
    position: relative;
    &.login-bg-dark{
        background-size: cover;
        background: url(../images/login-bg.png) no-repeat;
        .login-footer{
            color:rgba(255,255,255,.5);
            p{
            color:rgba(255,255,255,.4);
            }
            a{
                color:inherit;
                &:hover{
                    color:rgba(255,255,255,.8);
                }
            }
        }
    }
}

    .login-footer{
        text-align: center;
        position: absolute;
        bottom:0;
        width: 100%;
        a{
            text-decoration: none;
        }
        p{
            margin-bottom: 0;
            font-size: 14px;
            color:#CCC;
        }
    }

.login-wrapper{
    margin-bottom: 50px;
    >h1{
        color:white;
        font-weight: bold;
        font-size: 36px;
        margin: 0;
        text-align: center;
        padding-top: 100px;
        img{
            width:230px;
        }
    }
    .login-box{
        &.login-box-2{
            margin-top: 120px;
            box-shadow: 0 0 0 10px rgba(255,255,255,0.2), 5px 5px 15px rgba(0,0,0,0.0) ,1px 1px 3px rgba(0,0,0,0.2);
            padding:20px 70px 40px;
            h1{
                font-size: 26px;
                font-weight: bold;
                padding: 40px 0 30px;
                text-align: center;
            }
            .form-group{
                position: relative;
                margin-bottom: 30px;
                .help-block{
                    position: absolute;
                    left:0;
                    top:100%;
                }
            }
        }
        width:420px;
        padding:20px;
        margin:170px auto 0;
        background-color: #fff;
        box-shadow: 0 0 0 10px rgba(255,255,255,0.2), 5px 5px 15px rgba(0,0,0,0.0);
        .form-group{
            width: auto;
            .help-block{
                visibility:hidden;
            }
            &.has-danger{
                .help-block{
                    visibility:visible;
                }
            }
        }
        .form-control{
            height: 40px;
        }
        .img-code{
            width: 120px;
            height: 40px;
        }
        .status{
            height: 120px;
            margin-bottom: 30px;
            display: flex;
            align-items:center;
            justify-content:center;
            h5{
                font-size: 18px;
            }
            .iconfont{
                font-size: 40px;
                line-height: 1;
                vertical-align: middle;
            }
            .icon-success,
            .icon-i-success{
                color: green;
            }
            .icon-failed,
            .icon-i-error{
                color: rgb(229, 47, 63);
            }
            .icon-alert,
            .icon-i-alert{
                color: rgb(255, 184, 83);
            }
        }
        .form-inline .form-control{
            flex:1;
            margin-right: 10px;
        }
        .handle{
            .btn-block{
                height: 40px;
                line-height: 1;
            }
        }
    }
    .login-box .header{
        padding: 0px;
        margin: 0 0 40px 0;
        h2{
            font-size: 22px;
            font-weight: bold;
        }
    }
}


.city-page{
    background: #Fcfcfc;
    position: relative;
    &:after{
        content:none;
    }
    .city-banner{
        height: 200px;
        color:white;
        text-align: center;
        background: url(../images/bg.jpg) 50% 600px;
        h2{
            font-size:50px;
            text-shadow:0 0 10px rgba(0,0,0,0.2);
            font-weight: bold;
            line-height: 200px;
        }
    }
    .city-footer{
        color:#666 !important;
        padding:20px 0 0 0;
        position: relative;
        position: absolute;
        bottom:0;
        text-shadow:none;
        background-color: #f3f3f3;
        a{
            color:#666;
        }
    }

    .city-wrapper{
        width:1000px;
        margin:0px auto 69px;
        padding:20px 0;
        border:1px solid #F5f5f5;
        background-color: #fff;
        border-top:0;
        border-bottom:0;
        padding:10px;
        .sub-header{
            display: flex;
            align-items:center;
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom:1px solid #DDD;
            h2{
                font-size: 18px;
                display: inline-block;
                margin-right: 10px;
                margin-bottom: 0;
            }
            .small{
                font-size: 14px;
                color:#999;
            }
            .opt{
                margin-left: auto;
            }
        }
        .list{
            &:after{
                content:"";
                display: block;
                clear:both;
            }
        }

        dl{
            display: flex;
            align-items:center;
            flex-wrap:wrap;
            width: 45%;
            background-color: #f0f0f0;
            border-radius:10px;
            padding:20px 0;
            margin:15px 2.5%;
            position: relative;
            padding-left: 90px;
            height:100px;
            float:left;
            &:after{
                content:"";
                position: absolute;
                width: 10px;
                height:100%;
                background-color: #3496D9;
                right:0;
                border-radius:0 10px 10px 0;
            }
            &:before{
                content:"";
                position: absolute;
                width: 5px;
                height:60%;
                background-color: #256d9a;
                left:-5px;
                clip-path:polygon(0 5%, 100% 0, 100% 100%, 0 95%);
            }
            dt{
                position: absolute;
                left:-5px;
                display: inline-block;
                background-color: #3496D9;
                border-radius:0 30px 30px 0 / 30px;
                padding:16px 20px;
                color:white;
                box-shadow:3px 0 5px rgba(0,0,0,0.2);
            }
            dd{
                display: inline-block;
                font-size: 16px;
                margin-left: 30px;
                margin-bottom: 0;
            }
        }



    }
}


.table-user-info{
    margin-bottom: 20px;
    td{
        vertical-align: middle;
        height: 42px;
    }
    .user-avatar-m{
        margin-right: 10px;
    }
}

.user-status{
    position: absolute;
    right: 20px;
    font-size: 18px;
}

.video-list{
    video{
        width: 260px;
        height: 150px;
        margin: 0 10px 10px 0;
    }
}

.table-todo{
    a{
        color:inherit;
        &:hover{
            color: $color-primary;
        }
    }
}
.dot{
    width: 14px;
    height: 14px;
    border-radius:50%;
    display: inline-block;
    vertical-align: -2px;
    margin-left: 15px;
    &.dot-not{
        background-color:#C9CACA;
    }
    &.dot-underway{
        background-color: #609AED;
    }
    &.dot-done{
        background-color: #9AC944;
    }
}

.timeline{
    border-left:1px dashed #CCC;
    padding-left: 40px;
    margin-top: -20px;
    padding-top: 20px;
    margin-left: 20px;
    .cell{
        margin-bottom: 20px;
        position: relative;
        .head{
            background-color: #f4f4f4;
            display: inline-block;
            width: 380px;
            padding:30px 0;
            border-radius:10px;
            position: relative;
            margin-bottom: 10px;
            &:before{
                content:"";
                position: absolute;
                width: 100px;
                height: 1px;
                border-top:1px dashed #CCC;
                left:-40px;
                top: 50%;
            }
            &:after{
                content: "";
                position:absolute;
                width: 10px;
                height: 10px;
                top:50%;
                left:-40px;
                transform:translate(-50%,-50%);
                border-radius:50%;
                background-color: transparent;
            }
            .date{
                background-color: #99cb38;
                color:white;
                padding:5px 30px 5px 10px;
                position: absolute;
                left:-10px;
                top:50%;
                clip-path:polygon(0% 0%, 85% 0, 100% 50%, 85% 100%, 100% 100%, 0% 150%);
                transform:translateY(-50%);
                &:before{
                    content:"";
                    position: absolute;
                    width: 10px;
                    height: 8px;
                    background-color: #666;
                    left: 0;
                    top:100%;
                    clip-path:polygon(0% 0%, 100% 0, 100% 100%);
                }
            }
            .text{
                font-size: 16px;
                padding-left: 120px;
            }
        }
        &.active {
            .head{
                &:after{
                    background-color: $color-primary;
                }
                &:before{
                    border-color:$color-primary;
                }
                .date{
                    background-color: $color-primary;
                }
            }
        }
        .content{
            border:1px solid $color-border;
            padding:10px;
            max-width:700px;
            .toolbar.form-inline *:last-child{
                margin-right: 0;
            }
            >.table{
                margin-bottom: 0;
            }
        }
        /*
        &:hover{
        .content{
        display:inherit;
        }
        }
         */
    }
}

.flex-wrapper{
    display: flex;
    align-items:center;
    .handle{
        margin-left: auto;
    }

}






.link-refresh{
    font-size: 24px;
    display: block;
    &:focus,&:hover{
        text-decoration: none;
    }
}
.label-wrapper{
    label{
        min-width:150px;
    }
}


.sub-title{
    border:none;
    h5{
        span{
            display: inline-block;
            min-width:9em;
            border-bottom:3px solid $color-primary;
            padding-bottom: 10px;
        }
    }
}

.layer-single{
    padding:20px 0 20px 2em;
}




.toggle-full-screen{
  position: absolute;
  bottom:10px;
  right:10px;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  color:white;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  border-radius:5px;
  &:hover{
    background-color: rgba(0,0,0,0.8);
  }
  .iconfont{
    font-size: 40px;
  }
}




.video-player-list-sigle{
    position: relative;
    height: 100%;
    padding:15px;
    .cell{
        background-color: #8F9297;
        position: relative;
        height: 100%;
        video{
            position: absolute;
            width:100% !important;
            height: 100% !important;
            object-fit: fill;
            z-index: 1;
        }
        .title{
            position: absolute;
            z-index: 2;
            top:0;
            width: 100%;
            padding: 0 5px;
            background-color: rgba(255,255,255,.6);
        }
        .info{
            position: absolute;
            z-index: 2;
            bottom:0;
            width: 100%;
            padding: 0 5px;
            background-color: rgba(255,255,255,.6);
            display:inline-flex;
            .position{
                flex:1;
            }
            .resolution{
                margin-left: 10px;
            }
        }
    }
}




.video-player-list{
    position: relative;
    height: 100%;
    padding:15px;
    display:grid;
    grid-gap: 15px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    &.full-screen{
        grid-gap:unset;
        padding:0;
        background:#000;
    }
    .cell{
        background-color: #8F9297;
        position: relative;
        video{
            position: absolute;
            width:100% !important;
            height: 100% !important;
            object-fit: fill;
            z-index: 1;
        }
        .title{
            position: absolute;
            z-index: 2;
            top:0;
            width: 100%;
            padding: 0 5px;
            background-color: rgba(255,255,255,.6);
        }
        .info{
            position: absolute;
            z-index: 2;
            bottom:0;
            width: 100%;
            padding: 0 5px;
            background-color: rgba(255,255,255,.6);
            display:inline-flex;
            .position{
                flex:1;
            }
            .resolution{
                margin-left: 10px;
            }
        }
        .toolbar{
            position: absolute;
            z-index: 2;
            left: 10px;
            top: 50%;
            transform:translateY(-50%);
            transition:all 1s;
            margin-bottom: 0;
            .lock{
                display: block;
                cursor: pointer;
                visibility: hidden;
                color: rgba(255,255,255,.8);
                color:white;
                background-color: rgba(0,0,0,0.3);
                border-radius:50%;
                text-align: center;
                height: 20px;
                width: 20px;
                &.active{
                    color:#ffeb18;
                    visibility: visible;
                }
            }
        }
        &:hover{
            .toolbar .lock{
                visibility: visible;
            }
        }
    }
}


.tree-all{
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0;
    cursor: pointer;
        padding: 10px 30px;
        background-color: #F5F8FB;
        position: relative;
        &:after{
            content:"";
            position: absolute;
            right: 8px;
            top:15px;
            width: 12px;
            height: 12px;
            background-size:cover;
            background-image: url(' ../images/plus.svg ');
        }
        &:before{
            content:"";
            width: 6px;
            height: 6px;
            background-color: #ccc;
            position: absolute;
            left:15px;
            top:16px;
        }
        border-top:1px solid #E9E9E9;
        &.active{
            color:black;
            background-color: #EDF5FF;
            &:before{
                background-color: #6875E1;
            }
            &:after{
                background-image: url(' ../images/minus.svg ');
            }
            &:hover{
                background-color:#edf5ff;
            }
        }
}


.tree-all~.tree-province{
    display: none;
}
.tree-all.active~.tree-province{
    display:block;
}
.tree-province{
    padding-left: 0px;
    list-style-type:none;
    >li{
        padding-left: 0px;
        background-color: #F5F8FB;
        position: relative;
        &:after{
            content:"";
            position: absolute;
            right: 8px;
            top:15px;
            width: 12px;
            height: 12px;
            background-size:cover;
            background-image: url(' ../images/plus.svg ');
        }
        &:before{
            content:"";
            width: 6px;
            height: 6px;
            background-color: #CCC;
            position: absolute;
            left:15px;
            top:16px;
        }
        border-top:1px solid #E9E9E9;
        &+li{
        }
        >a{
            color:inherit;
            display: block;
            padding:10px 30px;
            text-decoration: none;
        }
        &.active{
            background-color:#edf5ff;
            &:after{
                background-image: url(' ../images/minus.svg ');
            }
            &:hover{
                background-color:#edf5ff;
            }
            >a{
                color:#000;
            }
            &:before{
                background-color: #6772E4;
            }
            >.city{
                display: block;
            }
        }
        &:hover{
            background-color: #fff;
        }
    }
    .city{
        display: none;
        padding-left: 30px;
        list-style-type:none;
        background-color: #fff;
        >li{
            position: relative;
            &:after{
                content:"";
                position: absolute;
                right: 8px;
                top:10px;
                width: 12px;
                height: 12px;
                background-size:cover;
                background-image: url(' ../images/plus.svg ');
            }
            &.active{
                &:after{
                    background-image: url(' ../images/minus.svg ');
                }
            }
            >a{
                color:inherit;
                display: block;
                text-decoration: none;
                padding: 5px 0;
            }
        }
    }
    .school{
        padding-left: 0;
        list-style-type:none;
        >li{
            position: relative;
            &:after{
                content:"";
                position: absolute;
                right: 8px;
                top:10px;
                width: 12px;
                height: 12px;
                background-size:cover;
                background-image: url(' ../images/plus.svg ');
            }
            &.active{
                &:after{
                    background-image: url(' ../images/minus.svg ');
                }
            }
            >a{
                padding:5px 0;
                color:inherit;
                display: inline-block;
                .icon-tel{
                    color:#666;
                }
            }
            @at-root .status.status-online{
                color:#609AED;
            }
            @at-root .status.status-offline{
                color:#CCC;
            }
            &.active{
                >a{
                    color:#609AED;
                }
            }
        }
    }
    .room{
        padding-left: 1em;
        list-style-type:none;
        >li{
            >a{
                display: inline-block;
                padding:5px 0;
                color:inherit;
                &:hover{
                }
            }
            &.active{
                >a{
                    color:#609AED;
                }
            }
        }
    }
}

.tree-province{
    &.city-only{
        >li{
            &.active{
                &:after,&:before{
                    content:none;
                }
            }
        }
    }
    >li{
        >.city{
            display: none;
            >li{
                >.school{
                    display: none;
                    >li{
                        >.room{
                            display: none;
                        }
                        &.active>.room{
                            display: block;
                        }
                    }
                }
                &.active>.school{
                    display: block;
                }
            }
        }
        &.active>.city{
            display: block;
        }
    }
}




.tree-header{
    font-size: 14px;
    padding:0 10px;
    span{
        color:#666;
    }
}

.nav-row-tree{
    width:270px !important;
    height: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction:column;



    &.float{
        position:fixed;
        z-index: 2;
        left:0;
        top:0;
        /*
        padding-top: 116px;
        */
    }
    >.content{
        flex:1;
        overflow:auto;
    }

    .fold-toolbar{
        display: none;
        position: relative;
        z-index: 2;
        text-align: right;
        margin-bottom: -10px;
        background-color: #F9F9F9;
        .bar{
            display: inline-block;
            padding:10px;
            cursor: pointer;
            &:hover{
                color:#5d98f0;
            }
            .inner{
                display: block;
                background-color: #fff;
                border:1px solid #CCC;
                padding:5px 10px;
            }
        }
    }

    &.folded{
        margin-left:-270px;
        .fold-toolbar{
            .bar{
                background-color: rgba(0,0,0,.3);
                transform:translateX(100%);
                &:hover{
                    opacity:1;
                }
            }
        }
    }

    .page-toolbar{
        text-align: right;
        padding:10px 10px;
        background-color: #fff;
        border-top:1px solid #E9E9E9;
        a:hover,a:focus{
            text-decoration: none;
        }

        >span{
            color:#5d98f0;
            display: inline-block;
            vertical-align: middle;
            font-size: 18px;
            &+span{
                margin-left: 10px;
            }
            &:hover{
                color:#0275d8;
                cursor: pointer;
            }
        }

        .view-toggle{
        
        }


        .full-screen-toggle{
            &.enter{
                .icon-full-screen{
                    display:block;
                }
                .icon-restore-screen{
                    display:none;
                }
            }
            .icon-full-screen{
                display:none;
            }
                .icon-restore-screen{
                    display:block;
                }

        }
    }
}

.search-form{
    margin:10px;
    position: relative;
    .btn-close{
        margin-right: 25px;
        .icon-close{
            color:inherit;
            font-size: 18px;
        }
    }
    button{
        position: absolute;
        top: 5px;
        right:5px;
        padding: 0;
        color:inherit;
            font-size: 20px;
        &:hover,&:focus{
            color:#0275d8;
            outline:none;
            box-shadow:none;
            text-decoration: none;
        }
    }
}


.page-viewer{
    height:100vh;
}


.video-info{
    padding: 10px;
    h3{
        font-size: 16px;
        padding: 5px 0;
    }
    .form-group{
        margin-bottom: 15px;
        label{
            margin-bottom: 0;
            color:#000;
        }
        .form-control-static{
            padding: 0;
        }
    }
}




.login-v2{
    background:#3E87FF url(../images/login-v2-illus.svg) no-repeat center;
    background-size: cover;
    position: relative;
    >.inner{
        position: relative;
        margin: 0 auto;
    }
    .logo{
        margin-top: 50px;
        text-align: center;
        img{
        width:300px;
        }
    }
    .login-title{
        display: none;
        flex:1;
        margin-top: 160px;
        margin-right: 3%;
        text-align: right;
        >img{
            width:50%;
        }
    }
    .login-wrapper{
        display: flex;
    }
    .login-form{
        margin-left: auto;
        background-color: #fff;
        margin-top: 80px;
        padding:70px 60px;
        width:90vw;
        label{
            cursor: pointer;
        }
        .form-check-input{
            vertical-align: -2px;
        }
        .title{
            font-size: 26px;
            font-weight: bold;
            margin-bottom: 30px;
        }
        .form-group{
            margin-bottom: 30px;
            .help-block{
                position: absolute;
            }
        }
        .form-control{
            border-radius:0;
            font-size: 16px;
            padding:15px 15px;
            max-width:unset;
        }
        .btn{
            font-size: 18px;
        }
    }
}



@media (min-width: 800px){
    .login-v2{
        .logo{
            text-align: left;
        }
        >.inner{
            width:780px;
        }
        .login-form{
            width:420px;
            padding:60px 40px;
        }
    }
}

@media (min-width: 1000px){
    .login-v2{
        >.inner{
            width:980px;
        }
        .login-title{
            display: block;
        }
        .login-form{
            width:420px;
            padding:60px 40px;
        }
    }
}

@media (min-width: 1200px){
    .login-v2{
        >.inner{
            width:1100px;
        }
        .login-form{
            width:380px;
            padding:60px 40px;
        }
    }
}

@media (min-width: 1400px){
    .login-v2{
        >.inner{
            width:1300px;
        }
        .login-form{
            width:430px;
            padding:60px 40px;
        }
    }
}

@media (min-width: 1600px){
    .login-v2{
        >.inner{
            width:1500px;
        }
        .login-form{
            width:520px;
            padding:70px 60px;
        }
    }
}



/*
@media (min-width: 1600px){
    .login-v2{
        >.inner{
            width:1500px;
        }
        .login-form{
            width:520px;
            padding:50px 40px;
        }
    }
}
*/
@media (min-height: 700px){
    .login-v2{
        .login-form{
            margin-top: 80px;
        }
        .login-title{
            margin-top: 180px;
        }
    }
}
@media (min-height: 800px){
    .login-v2{
        .login-form{
            margin-top: 120px;
        }
        .login-title{
            margin-top: 220px;
        }
    }
}

.sidebar-row{
  background-color: #fff !important;
  padding:0px 15px;
  .cell{
    border:1px solid #CCC;
    width:90%;
    margin:20px auto;
    padding:10px;
    border-radius:5px;
    box-shadow: inset 0px 3px 3px rgba(0,0,0,0.2);
    display: block;
    text-align: center;
    color:inherit;
    text-decoration: none;
    &:hover{
      background-color: #fefefe;
      .number{
        color: $color-primary;
      }
    }
    .number{
      font-size: 32px;
      display: block;
    }
    .title{
      display:block;
      padding-bottom: 5px;
    }
  }
}

.s-title{
  border-bottom:1px solid #CCC;
  padding-bottom:10px;
  margin-bottom:20px;
  margin-top: 10px;
}


.date-table{
  .cell{
  display: flex;
  background-color: #fcfcfc;
  margin-bottom: 20px;
  padding:10px;
  }
  .date-wrapper{
    width:120px;
  }
  .table-wrapper{
    flex:1;
  }
}
.date{
  width:100px;
  text-align: center;
  border-width:10px 2px 2px 2px;
  border-style:solid;
  border-color:#CCC;
  padding:20px 0 5px;
  position: relative;
  margin-top: 20px;
  background-color: #fff;
  margin-left: 5px;
  &:after{
    content:"";
    position: absolute;
    width:15px;
    height:30px;
    background-color: #CCC;
    top:-20px;
    left:20%;
  }
  &:before{
    content:"";
    position: absolute;
    width:15px;
    height:30px;
    background-color: #CCC;
    top:-20px;
    right:20%;
  }
  .day{
    font-size: 32px;
    line-height: 1.2;
  }
}

.show-more{
  padding: 10px;
  margin-top: 20px;
}

.null-wrapper{
  text-align: center;
  background-color: #FCFCFC;
  min-height:240px;
  display: flex;
  justify-content:center;
  align-items:center;
  margin-bottom: 20px;
}
