@charset "UTF-8";
@import "iconfont.css";
/*
$color-primary: #6772e4;
 */
html, body {
  min-width: 1200px;
  min-height: 100vh; }

body {
  /*
    background-color: #2485CC;
    background-image: linear-gradient(90deg,#577cae, #6b88b8);
    background-image: linear-gradient(90deg,#4553FF, #10BEFF);
    background-image: linear-gradient(217deg, rgba(57, 179, 255, 0.8), rgba(100, 82, 255, 0) 70.71%), linear-gradient(127deg, rgba(5, 27, 255, 0.8), rgba(198, 250, 255, 0) 70.71%);
     */
  background: url(../images/bg.png) no-repeat;
  background-position: top left;
  background-size: 100% 230px;
  margin: 0;
  font: 14px/1.5 Arial, "Microsoft Yahei";
  display: flex;
  flex-direction: column;
  color: #566573;
  overflow-y: scroll; }
  body.role-tenement {
    background-image: linear-gradient(to right, #536d9d, #768ec7); }
  body.role-viewer {
    /*
        background-image: linear-gradient(90deg,#3cae75, #6b88b8);
         */
    background-image: linear-gradient(90deg, #ae6f8e, #6b88b8); }

a {
  color: #5D98F0; }

.mr10 {
  margin-right: 10px; }

.mt20 {
  margin-top: 20px; }

.btn, .form-control {
  font-size: 14px; }

.btn {
  cursor: pointer; }
  .btn[disabled] {
    cursor: not-allowed;
    filter: grayscale(100%); }

.main-header {
  background-color: #2C3E50;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: white;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.2);
  min-height: 41px; }
  .main-header h1 {
    margin: 0;
    font-size: 20px; }
    .main-header h1 span {
      font-family: Arial;
      /*
            color:#E53A30;
             */
      font-weight: bold;
      font-size: 26px;
      line-height: 1;
      vertical-align: -2px;
      margin-right: 3px; }
  .main-header .logo {
    width: 100px; }
  .main-header .status {
    flex: 1; }
    .main-header .status .inner {
      display: inline-block;
      /*
            background-color: #475669;
            border:1px solid #556370;
            background-color: rgba(0,0,0,0.3);
            border:1px solid rgba(255,255,255,.2);
             */
      padding: 5px 10px;
      border-radius: 3px;
      margin-left: 10px; }
      .main-header .status .inner span {
        color: #ececec; }
      .main-header .status .inner a {
        color: #CCC;
        margin-left: 15px; }
        .main-header .status .inner a:hover {
          color: #FFF;
          text-decoration: none; }
  .main-header aside {
    margin-left: auto; }
    .main-header aside a {
      color: #F0F0F0;
      text-decoration: none;
      margin-left: 10px; }
      .main-header aside a:hover {
        color: white; }
      .main-header aside a:last-child {
        border-left: 1px solid #CCC;
        padding-left: 10px; }

.main-nav {
  background-color: #425262;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  /*
    border-bottom:5px solid rgba(0,0,0,0.3);
     */ }
  .main-nav > span {
    display: flex; }
    .main-nav > span:last-child {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: -1px 0 0 0 rgba(255, 255, 255, 0.3); }
  .main-nav a {
    min-width: 80px;
    color: #EEE;
    font-size: 14px;
    text-decoration: none;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 1px;
    /*
    border-right:1px solid #35424E;
     */
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 1px 0 0 0 rgba(255, 255, 255, 0.1);
    padding: 6px 8px 6px; }
    .main-nav a .iconfont {
      font-size: 25px;
      margin-bottom: 5px; }
    .main-nav a.special {
      /*
    background-color:  #3f608a!important;
     */
      background-color: rgba(27, 68, 113, 0.7);
      color: #EEE; }
      .main-nav a.special:hover {
        background-color: rgba(27, 68, 113, 0.7);
        color: #EEE; }
    .main-nav a.active {
      color: white;
      /*
            background-color: #2C3E50;
            background-color: darken(#425262, 5%);
             */
      background-color: rgba(0, 0, 0, 0.3); }
      .main-nav a.active:hover {
        color: white;
        background-color: rgba(0, 0, 0, 0.2);
        /*
    background-color: darken(#425262, 5%);
     */ }
    .main-nav a:hover {
      color: rgba(255, 255, 255, 0.8);
      /*
            background-color: lighten(#425262, 1%);
             */
      background-color: rgba(0, 0, 0, 0.1); }

.main-section {
  flex: 1;
  display: flex; }
  .main-section > .container-fluid {
    flex: 1;
    display: flex;
    padding: 0; }
  .main-section .main-row {
    display: flex;
    width: 100%;
    flex: 1; }
  .main-section .content-row {
    flex: 1;
    position: relative;
    z-index: 1; }

.main-row > .main-side-nav {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e9e9e9; }
  .main-row > .main-side-nav > .cell {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .main-row > .main-side-nav > .cell:first-child {
      border-bottom: 1px solid #e9e9e9; }
    .main-row > .main-side-nav > .cell:nth-child(2) {
      margin-top: auto; }
    .main-row > .main-side-nav > .cell > a {
      padding: 10px 13px;
      color: grey;
      text-decoration: none; }
      .main-row > .main-side-nav > .cell > a.active {
        color: #6772e4; }
        .main-row > .main-side-nav > .cell > a.active:hover {
          color: #6772e4; }
      .main-row > .main-side-nav > .cell > a:hover {
        color: #676767; }
  .main-row > .main-side-nav .iconfont {
    font-size: 24px; }

.main-row > .nav-row {
  border-right: 1px solid #E9E9E9;
  background-color: #F9f9f9;
  width: 200px; }
  .main-row > .nav-row .nav-side {
    list-style: none;
    padding-left: 0;
    margin: 0;
    flex-direction: column; }
    .main-row > .nav-row .nav-side .nav-link {
      padding: 15px 15px;
      display: block;
      border-bottom: 1px solid #dbdbdb;
      border-radius: 0;
      color: inherit;
      text-decoration: none; }
      .main-row > .nav-row .nav-side .nav-link .iconfont {
        display: none; }
      .main-row > .nav-row .nav-side .nav-link:hover {
        color: #555; }
      .main-row > .nav-row .nav-side .nav-link.active {
        background-color: #fff;
        color: #5D98F0; }
      .main-row > .nav-row .nav-side .nav-link:before {
        content: "";
        width: 5px;
        height: 5px;
        background-color: currentColor;
        opacity: 0.5;
        display: inline-block;
        vertical-align: 3px;
        margin-right: 3px; }

.main-row > .content-row {
  background-color: #fff; }
  .main-row > .content-row .breadcrumb {
    display: flex;
    border-bottom: 1px solid #E9E9E9;
    border-radius: 0;
    padding: 15px 15px;
    background-color: #F4F4F4;
    margin-bottom: 0; }
    .main-row > .content-row .breadcrumb em {
      flex: 1;
      font-style: normal;
      font-size: 14px; }
  .main-row > .content-row .sub-header {
    border-bottom: 1px solid #E9E9E9;
    padding: 0 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    min-height: 52px; }
    .main-row > .content-row .sub-header h2 {
      font-size: 18px;
      flex: 1;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      color: #2D3E4F;
      font-weight: bold; }
      .main-row > .content-row .sub-header h2 .small {
        font-size: 14px;
        font-weight: normal;
        color: #666;
        margin-left: 10px;
        border-left: 1px solid currentColor;
        padding-left: 10px; }
  .main-row > .content-row .padding-wrapper {
    padding: 0 15px 15px; }
    .main-row > .content-row .padding-wrapper > .nav-tab {
      margin: -10px -15px 10px; }

.links-wrapper a {
  margin-right: 10px; }
  .links-wrapper a:last-child {
    margin-right: 0px; }

.buttons-wrapper > .btn {
  margin-right: 10px; }
  .buttons-wrapper > .btn:last-child {
    margin-right: 0; }

.buttons-wrapper > .btn-group {
  margin-right: 10px; }

.has-required .col-form-label:before {
  content: "*";
  color: red;
  margin-right: 5px;
  display: inline-block;
  vertical-align: -2px; }

.help-block {
  font-size: 12px;
  margin: 5px 0 0 0; }

.has-danger .help-block {
  color: #D9534F; }

.col-form-label {
  text-align: right; }
  .col-form-label:after {
    content: ":";
    margin-right: -5px;
    padding-left: 5px; }
  .col-form-label.label-empty:after {
    content: none; }

.custom-control {
  margin-bottom: 0;
  min-height: 14px;
  margin-top: 3px; }
  .custom-control .custom-control-input {
    visibility: hidden; }
    .custom-control .custom-control-input:focus {
      outline-width: 0; }
    .custom-control .custom-control-input[disabled] ~ .custom-control-indicator {
      border-color: #CCC;
      cursor: not-allowed !important; }
  .custom-control .custom-control-indicator {
    top: 0; }
  .custom-control .custom-control-description {
    margin-top: 1px;
    line-height: 14px; }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#AAA' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  cursor: not-allowed !important; }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#AAA'/%3E%3C/svg%3E"); }

.label-wrapper {
  /*
    display: flex;
    justify-content: space-between;
     */
  margin-top: 8px; }
  .label-wrapper label {
    /*
    min-width:230px;
     */
    margin-right: 8px;
    padding-left: 20px; }

.custom-control {
  margin-right: 0; }

.flex-inline {
  display: flex; }

.label-opt-wrapper {
  margin-top: 30px; }
  .label-opt-wrapper .form-inline {
    margin: 20px 0; }
  .label-opt-wrapper .form-group {
    width: 100%;
    display: flex; }
    .label-opt-wrapper .form-group .form-control {
      flex: 1; }
    .label-opt-wrapper .form-group .opt {
      margin-left: 5px;
      min-width: 40px; }

.form-group {
  margin-bottom: 20px; }

.toolbar {
  margin: 0px 0 10px; }
  .toolbar.toolbar-bg {
    /*
        background-color: #F4F4F4;
        padding:10px;
         */
    border-bottom: 1px dashed #D9D9D9;
    padding-bottom: 10px; }
  .toolbar.form-inline select, .toolbar.form-inline input, .toolbar.form-inline button {
    margin-right: 10px; }
  .toolbar .form {
    flex: 1; }
  .toolbar .filter > span {
    margin-right: 15px; }
    .toolbar .filter > span:last-child {
      margin-right: 0; }
  .toolbar .filter a {
    color: gray; }
    .toolbar .filter a:hover, .toolbar .filter a.active {
      text-decoration: none;
      color: #333; }
  .toolbar .filter .badge {
    vertical-align: middle; }

.toolbar-table {
  /*
    border:1px solid $color-border;
    border-bottom:none;
     */
  margin: 0px 0 10px; }
  .toolbar-table .active {
    background-color: #fff;
    border-color: #1477D5;
    color: #1477D5;
    cursor: default; }

.toolbar-tips {
  border: 1px solid #E9E9E9;
  border-bottom-width: 0;
  background-color: rgba(255, 255, 0, 0.26);
  text-align: center;
  padding: 3px 0; }

.table-custom thead > tr > th {
  background-color: #F4F4F4;
  vertical-align: middle !important;
  border-right-color: #e1e1e1; }

.table-custom tbody > tr > th {
  vertical-align: middle !important;
  padding-top: 0;
  padding-bottom: 0;
  height: 40px; }

.table-custom tbody > tr > td {
  vertical-align: middle;
  border-right: none;
  padding-top: 0;
  padding-bottom: 0;
  height: 50px; }

.table-custom time, .table-custom .small {
  font-size: 12px; }
  .table-custom time span, .table-custom .small span {
    display: block; }

.table-custom tbody > tr:hover td[rowspan] {
  background-color: white; }

.table-custom tr.active, .table-custom tr.active:hover {
  background-color: #ffffc7; }

.table-custom .form-control {
  padding-top: 4px;
  padding-bottom: 4px; }

.table-custom select.form-control {
  height: 25px !important;
  line-height: 25px !important;
  padding-top: 0px !important; }

.table-custom .align-top {
  vertical-align: top;
  padding-top: 20px; }

.table-custom .caption-top {
  caption-side: top; }

.table-custom .tr-collapse {
  border-top: 1px solid red; }
  .table-custom .tr-collapse td {
    padding: 10px; }
  .table-custom .tr-collapse:hover {
    background-color: transparent; }
  .table-custom .tr-collapse .table-custom {
    margin-bottom: 0; }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.03); }

.table-bordered tbody > tr > td {
  border-left: 1px solid #ECEEEF; }

.table-nohover tbody tr:hover {
  background-color: transparent; }

.table tbody + tbody {
  border-top-width: 1px; }

.table-modal-add {
  border-left: none !important; }
  .table-modal-add .form-control[type="number"] {
    display: inline-block;
    width: 65px;
    text-align: right; }
  .table-modal-add tbody:after {
    content: "";
    display: table-row;
    border: none;
    height: 15px;
    margin-left: -3px;
    position: relative;
    left: -10px; }
  .table-modal-add tbody:last-child:after {
    content: none; }

.table-sticky-custom thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 3; }

.menu-wrapper {
  display: flex;
  margin-top: -10px; }
  .menu-wrapper .nav-row {
    border-right: 1px solid #E9E9E9; }
    .menu-wrapper .nav-row .nav-side {
      list-style: none;
      padding-left: 0;
      margin: 0;
      margin-bottom: 15px;
      flex-direction: column;
      min-width: 165px; }
      .menu-wrapper .nav-row .nav-side.nav-collapse {
        /*
                min-width: 240px;
                 */ }
        .menu-wrapper .nav-row .nav-side.nav-collapse .ul-collapse {
          margin-left: 10px;
          margin-bottom: 15px; }
          .menu-wrapper .nav-row .nav-side.nav-collapse .ul-collapse li {
            padding: 5px 0; }
            .menu-wrapper .nav-row .nav-side.nav-collapse .ul-collapse li > a {
              color: inherit; }
            .menu-wrapper .nav-row .nav-side.nav-collapse .ul-collapse li.active {
              list-style-type: disc; }
            .menu-wrapper .nav-row .nav-side.nav-collapse .ul-collapse li.active, .menu-wrapper .nav-row .nav-side.nav-collapse .ul-collapse li.active > a {
              color: #5D98F0; }
        .menu-wrapper .nav-row .nav-side.nav-collapse .nav-link::before {
          left: 25px;
          top: 20px;
          transition: all 500ms;
          transform: rotate(90deg);
          transform-origin: 0% 25%; }
        .menu-wrapper .nav-row .nav-side.nav-collapse .nav-link.collapsed::before {
          left: 20px;
          transform: rotate(0deg); }
      .menu-wrapper .nav-row .nav-side .nav-item {
        border-bottom: 1px solid #E9E9E9; }
      .menu-wrapper .nav-row .nav-side .nav-link {
        padding: 15px 15px;
        display: block;
        color: inherit;
        text-decoration: none;
        padding-left: 30px;
        position: relative;
        border-radius: 0; }
        .menu-wrapper .nav-row .nav-side .nav-link .iconfont {
          float: right;
          font-size: 20px;
          margin-top: -2px; }
        .menu-wrapper .nav-row .nav-side .nav-link:before {
          content: "";
          position: absolute;
          top: calc(50% - 2px);
          transform: translate(-100%, -50%);
          width: 0;
          height: 0px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent transparent currentColor; }
        .menu-wrapper .nav-row .nav-side .nav-link.active {
          background-color: #F4F4F4;
          color: #5D98F0; }

.icon-done {
  color: #2ECC71; }

.icon-close {
  color: #E74C3C; }

.confirm-wrapper {
  background-color: #f4f4f4;
  padding: 15px;
  margin: 20px 0; }
  .confirm-wrapper textarea {
    flex: 1; }

.nav-tab {
  border-bottom: 1px solid #E9E9E9; }
  .nav-tab .nav-link {
    color: inherit;
    position: relative;
    padding: 15px 20px; }
    .nav-tab .nav-link.active {
      color: #5D98F0;
      background-color: transparent; }
      .nav-tab .nav-link.active:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%) rotate(-45deg);
        background-color: white;
        border: 1px solid;
        border-color: #E9E9E9 #E9E9E9 transparent transparent; }

.card {
  border: none; }
  .card .card-block {
    background-color: #F4F4F4; }
  .card .card-title {
    font-size: 18px !important;
    margin-bottom: 0; }
  .card .iconfont {
    font-size: 40px;
    vertical-align: middle;
    margin-right: 10px; }

.iconfont {
  font-size: inherit; }

.icon-success,
.icon-success {
  color: green; }

.icon-failed,
.icon-error {
  color: #e52f3f; }

.icon-alert,
.icon-alert {
  color: #ffb853; }

.modal {
  /*
     * modal align center
     * */
  text-align: center; }
  .modal .modal-body {
    max-height: 75vh;
    overflow: auto;
    min-width: 500px; }
  .modal .modal-header {
    padding: 10px 15px;
    background-color: #3A82FC;
    color: white; }
    .modal .modal-header .close {
      color: white;
      text-shadow: none; }
  .modal .modal-title {
    font-size: 16px; }
  .modal .desc {
    width: 80%;
    margin: auto;
    text-align: justify; }
  .modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px; }
  .modal .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle; }

.modal-lg {
  width: 800px; }

.initial span {
  color: #5D98F0;
  margin: 0 5px 5px 0;
  border: 1px solid #5D98F0;
  text-align: center;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  display: inline-block;
  font-weight: bold;
  cursor: pointer; }
  .initial span.active {
    background-color: #5D98F0;
    color: white;
    cursor: default; }

.selector {
  display: flex;
  flex-wrap: wrap;
  max-height: 190px;
  overflow: auto; }
  .selector span {
    border: 1px dashed #5D98F0;
    padding: 5px 10px;
    display: inline-block;
    margin: 0 10px 10px 0px;
    width: 23%;
    cursor: pointer; }
    .selector span:nth-child(4n) {
      margin-right: 0; }
    .selector span em {
      display: block;
      font-style: normal; }

.date-setting {
  /*
    width:590px !important;
     */
  border: 1px solid #E9E9E9;
  padding: 10px; }
  .date-setting .cell {
    display: flex;
    border-top: 1px solid #E9E9E9;
    padding-top: 15px; }
    .date-setting .cell:first-child {
      border-top: none; }
    .date-setting .cell .label {
      white-space: nowrap;
      margin-right: 20px;
      min-width: 5em;
      text-align: right;
      font-weight: bold; }
    .date-setting .cell .content {
      flex: 1; }
      .date-setting .cell .content .config {
        padding-bottom: 15px;
        border-bottom: 1px dashed #E9E9E9; }
        .date-setting .cell .content .config .custom-control {
          width: 125px; }
      .date-setting .cell .content .form-inline {
        margin: 15px 0; }
        .date-setting .cell .content .form-inline input[type="date"] {
          width: 260px; }
        .date-setting .cell .content .form-inline .input-time {
          margin: 0 10px;
          width: 110px !important; }

.icon-plus, .icon-minus {
  font-size: 20px;
  cursor: pointer; }

.icon-plus {
  color: #2ECC71; }
  .icon-plus:hover {
    color: #25a25a; }

.icon-minus {
  color: #FF5E4C; }
  .icon-minus:hover {
    color: #ff3019; }

.modal-body .padding-v {
  padding: 10px 0; }

.label-opt-wrapper {
  margin-top: 30px; }
  .label-opt-wrapper .form-inline {
    margin: 20px 0; }
  .label-opt-wrapper .form-group {
    width: 100%;
    display: flex; }
    .label-opt-wrapper .form-group .form-control {
      flex: 1; }
    .label-opt-wrapper .form-group .opt {
      margin-left: 5px;
      min-width: 40px; }

.form-group {
  margin-bottom: 20px; }

/*
 * some bugs fixed for bootstrap v3 plugin
 * bootstrap-datetimepicker
 */
@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("../fonts/glyphicons-halflings-regular.eot");
  src: url("../fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("../fonts/glyphicons-halflings-regular.woff") format("woff"), url("../fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg"); }

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.glyphicon-th:before {
  content: "\e011"; }

.glyphicon-remove:before {
  content: "\e014"; }

.glyphicon-arrow-left:before {
  content: "\e091"; }

.glyphicon-arrow-right:before {
  content: "\e092"; }

.glyphicon-trash:before {
  content: "\e020"; }

.glyphicon-upload:before {
  content: "\e027"; }

.glyphicon-folder-open:before {
  content: "\e118"; }

.glyphicon-file:before {
  content: "\e022"; }

.glyphicon-zoom-in:before {
  content: "\e015"; }

.glyphicon-resize-vertical:before {
  content: "\e119"; }

.glyphicon-fullscreen:before {
  content: "\e140"; }

.glyphicon-resize-full:before {
  content: "\e096"; }

.glyphicon-remove:before {
  content: "\e014"; }

.glyphicon-ban-circle:before {
  content: "\e090"; }

.glyphicon-hand-down:before {
  content: "\e130"; }

.dropdown-toggle::after {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%); }

.dropdown-menu {
  font-size: 14px;
  margin-top: 8px; }
  .dropdown-menu .dropdown-item {
    padding: 3px 1rem; }

.multiselect-native-select .btn-default {
  background-color: #fff;
  border: 1px solid #CCC;
  padding-left: 10px;
  outline: 0; }

.multiselect-container > li > a > label {
  color: #333;
  padding-left: 16px !important; }

.multiselect-container > li.active {
  background-color: #0275D8; }

.multiselect-container > li.active > a > label {
  color: white; }

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 10px !important; }

.city-picker-span {
  padding-left: 5px; }

.select2-container .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 35px !important; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 35px !important; }

.sub-title {
  border-bottom: 1px solid #E9E9E9;
  padding-bottom: 10px;
  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;
  width: 100%;
  min-height: 46px;
  padding-bottom: 0; }
  .sub-title .opt, .sub-title .handle {
    margin-left: auto; }
  .sub-title h5 {
    font-size: 16px;
    margin-bottom: 0; }

.col-10.single-column {
  max-width: 900px; }

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px; }

.pagination > li {
  display: inline; }

.pagination > li > a, .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd; }

.pagination > li:first-child > a, .pagination > li:first-child > span {
  margin-left: 0; }

.pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd; }

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #5A98DE;
  border-color: #5A98DE; }

.nav-pagination .pagination {
  /* margin-right: 20px; */ }

.nav-pagination .btn-sm {
  min-width: auto; }

.nav-pagination .input-text {
  width: 68px; }

.nav-pagination {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.nav-pagination .jump {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.data-empty {
  text-align: center;
  padding: 100px 0; }
  .data-empty .iconfont {
    font-size: 120px;
    color: #CCC; }
  .data-empty p {
    color: #AAA;
    margin-top: -20px; }

.input-file {
  display: flex;
  position: relative; }
  .input-file input[type="text"] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-file input[type="file"] {
    position: absolute;
    right: 0;
    width: 90px;
    height: 100%;
    opacity: 0;
    cursor: pointer; }
  .input-file button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.input-image {
  border: 1px dashed #CCC;
  display: inline-block;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .input-image:hover {
    border-color: #1FB922; }
  .input-image:hover:after {
    color: #1FB922; }
  .input-image:after {
    position: absolute;
    z-index: 0;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    text-align: center;
    content: "\e60c";
    font-family: "iconfont" !important;
    font-size: 60px;
    color: gray; }
  .input-image > img {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    z-index: 1; }
  .input-image input[type="file"] {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer; }

select.form-control:not([size]):not([multiple]) {
  height: 35px;
  /*
    line-height: 35px;
     */
  padding-top: 5px; }

.form-control {
  max-width: 460px; }

input.form-control[readonly] {
  background-color: #fff !important; }

.col-suffix {
  position: relative;
  display: inline-block;
  width: 460px; }
  .col-suffix > .suffix {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(calc(100% + 10px), 50%); }
  .col-suffix > select {
    margin-bottom: 20px; }

.menu-wrapper .single-column .form-group > label {
  width: 13em;
  flex-basis: 13em; }

.menu-wrapper .single-column .offset-sm-3 {
  margin-left: 13em; }

@media (min-width: 1200px) {
  .col-6 > .form-group > label.col-4 {
    max-width: 13em; } }

.form-control::placeholder {
  color: #a2a2a2; }

.form-control.text-size-5 {
  width: 120px; }

.form-control.text-size-10 {
  width: 200px; }

.custom-control-indicator {
  border: 1px solid #0275D8;
  background-color: #fff; }

.content-row {
  display: flex;
  flex-direction: column; }
  .content-row > .padding-wrapper {
    padding-top: 8px !important; }
  .content-row > .content {
    display: flex;
    flex: 1;
    flex-direction: column; }
    .content-row > .content > .padding-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column; }
      .content-row > .content > .padding-wrapper > .container-fluid {
        width: 100%; }
    .content-row > .content > .menu-wrapper {
      flex: 1; }

button, input, optgroup, select, textarea {
  font-family: inherit; }

.modal .selector-wrapper {
  min-width: auto;
  display: flex; }
  .modal .selector-wrapper .cell {
    flex: 1; }
  .modal .selector-wrapper .handle {
    width: 40px; }
  .modal .selector-wrapper .border-wrapper {
    height: 460px; }

.selector-wrapper {
  margin-top: 5px;
  display: flex;
  min-width: 800px; }
  .selector-wrapper .toolbar-table .form-control {
    width: 180px; }
  .selector-wrapper .handle {
    margin-top: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .selector-wrapper .handle .btn {
      display: block;
      margin: 10px; }
  .selector-wrapper .table > tbody > tr > td {
    width: 120px; }
    .selector-wrapper .table > tbody > tr > td:nth-child(1) {
      padding-right: 0px;
      width: 0; }
  .selector-wrapper .border-wrapper {
    border: 1px solid #ECEEEF;
    /*
        min-height:128px;
         */
    height: 330px;
    overflow: auto; }
    .selector-wrapper .border-wrapper .table-bordered {
      margin-bottom: 0; }

.mb20 {
  margin-bottom: 20px; }

.mtb20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.text-right {
  text-align: right; }

.dashboard {
  border-bottom: 1px solid #E9E9E9;
  margin-bottom: 15px;
  /*
    display:grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap:30px 50px;
     */ }
  .dashboard:after {
    content: "";
    display: block;
    clear: both; }
  .dashboard a {
    color: inherit; }
    .dashboard a:hover {
      color: #5D98F0;
      text-decoration: none; }
      .dashboard a:hover .text {
        color: inherit; }
  .dashboard .cell {
    border: 1px solid #E9E9E9;
    background-color: #fff;
    border-left: 4px solid #CCC;
    padding: 10px 0 10px 20px;
    display: inline-block;
    width: 19.2%;
    margin-right: 1%;
    margin-bottom: 15px;
    float: left; }
    .dashboard .cell:nth-child(5n) {
      margin-right: 0; }
    .dashboard .cell .text {
      font-size: 40px;
      line-height: 1;
      color: #666; }
    .dashboard .cell:nth-child(1) {
      border-left-color: #8aa4cc; }
    .dashboard .cell:nth-child(2) {
      border-left-color: #a8cc70; }
    .dashboard .cell:nth-child(3) {
      border-left-color: #b690cc; }
    .dashboard .cell:nth-child(4) {
      border-left-color: #6cccc6; }
    .dashboard .cell:nth-child(5) {
      border-left-color: #b6ccb3; }
    .dashboard .cell:nth-child(6) {
      border-left-color: #cca874; }
    .dashboard .cell:nth-child(7) {
      border-left-color: #cc6aa1; }
    .dashboard .cell:nth-child(8) {
      border-left-color: #ddc5c7; }
    .dashboard .cell:nth-child(9) {
      border-left-color: #8688cc; }
    .dashboard .cell:nth-child(10) {
      border-left-color: #adbdc6; }

/*
.dashboard{
padding: 20px 0 30px;
border-bottom:1px solid $color-border;
margin-bottom: 20px;
display:grid;
grid-template-columns: repeat(5, 1fr);
grid-template-rows: repeat(6, 1fr);
grid-gap:20px;
.cell{
display: flex;
text-align: center;
border:1px solid $color-border;
.icon,.content{
}
.icon{
background-color: #ECF0F1;
width: 60px;
display: flex;
align-items:center;
justify-content:center;
.iconfont{
font-size: 40px;
}
}
.content{
display:flex;
flex:1;
flex-direction:column;
justify-content:center;
text-align: left;
padding-left: 10px;
.text{
font-size: 40px;
line-height: 1;
font-weight: bold;
}
}
}
&.more{
}
}
 */
.form-apply {
  display: flex;
  width: 100%;
  margin-top: 20px;
  flex-direction: row-reverse; }
  .form-apply .handle .user-avatar {
    width: 160px;
    height: 200px;
    margin-bottom: 20px;
    background: #EFEFEF url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyBjbGFzcz0iaWNvbiIgd2lkdGg9IjIwMHB4IiBoZWlnaHQ9IjIwMC4wMHB4IiB2aWV3Qm94PSIwIDAgMTAyNCAxMDI0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTY2NS42IDY1Ni41NjVjMTA1LjQxMi01NC4yMTIgMTc3LjY5NC0xNjguNjYgMTc3LjY5NC0yOTUuMTUzIDAtMTgzLjcxOC0xNDcuNTc2LTMzNC4zMDYtMzMxLjI5NC0zMzQuMzA2UzE4MC43MDYgMTc0LjY4MiAxODAuNzA2IDM1OC40YzAgMTI2LjQ5NCA3Mi4yODIgMjQzLjk1MyAxNzcuNjk0IDI5OC4xNjUtMTc0LjY4MiA1MS4yLTI5OC4xNjUgMTk1Ljc2NC0yOTguMTY1IDM0Ni4zNTNoNjAuMjM2QzEyMC40NyA4MjIuMjEyIDI5NS4xNTMgNzAxLjc0IDUxMiA3MDEuNzRzMzkxLjUzIDEyMC40NyAzOTEuNTMgMzAxLjE3N2g2MC4yMzVjMC0xNTAuNTg5LTEyMy40ODMtMjk1LjE1My0yOTguMTY1LTM0Ni4zNTN6TTI0MC45NDEgMzU4LjRjMC0xNTAuNTg4IDEyMC40Ny0yNzEuMDU5IDI3MS4wNTktMjcxLjA1OVM3ODMuMDU5IDIwNy44MTEgNzgzLjA1OSAzNTguNCA2NjIuNTg5IDYyOS40NTkgNTEyIDYyOS40NTkgMjQwLjk0MSA1MDUuOTc2IDI0MC45NDEgMzU4LjR6IiBmaWxsPSIjYmZiZmJmIiAvPjwvc3ZnPg==) no-repeat center center;
    background-size: 90%; }
  .form-apply .handle .buttons-wrapper {
    display: flex;
    padding: 0 30px;
    justify-content: space-around; }
  .form-apply .form {
    flex: 1; }

.form-apply .col-form-label {
  width: 118px !important;
  flex: none;
  max-width: auto !important; }

.form-apply-more .row > div:first-child .col-form-label {
  width: 118px !important;
  flex: none;
  max-width: auto !important; }

.form-apply-text .form {
  margin-right: 20px; }
  .form-apply-text .form .table-custom th, .form-apply-text .form .table-custom td {
    text-align: center; }
  .form-apply-text .form .table-custom td {
    height: 40px; }
  .form-apply-text .form .table-custom th {
    vertical-align: middle; }

.table-text-center th, .table-text-center td {
  text-align: center; }

.guage {
  display: flex;
  margin: 10px 0; }
  .guage > span {
    padding: 10px;
    flex: 1;
    text-align: center;
    background-color: #EEE;
    position: relative;
    clip-path: polygon(0 0, 95% 0, 100% 50%, 95% 100%, 0 100%, 5% 50%); }
    .guage > span:first-child {
      clip-path: polygon(0 0, 95% 0, 100% 50%, 95% 100%, 0 100%, 0 0); }
    .guage > span:last-child {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 5% 50%); }
    .guage > span.active {
      background-color: #75b76c;
      color: white; }

.table-text-wrapper {
  background-color: #f9f9f9;
  padding: 5px 10px; }
  .table-text-wrapper .table-custom th,
  .table-text-wrapper .table-custom td {
    background-color: #fff;
    text-align: center; }
  .table-text-wrapper .table-custom th {
    vertical-align: middle;
    border-bottom-width: 1px; }
  .table-text-wrapper .table-bordered td {
    border: 1px solid #E9E9E9; }

.hr-dash {
  border-top-style: dashed; }

.circle-outline {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  border: 1px solid #999;
  border-radius: 100%; }

.table-has-danger-off .has-danger .form-control {
  border-color: #D9D9D9; }
  .table-has-danger-off .has-danger .form-control:focus {
    border-color: #5cb3fd; }

.layer-single-line {
  font-size: 18px; }
  .layer-single-line .iconfont {
    font-size: 40px;
    vertical-align: middle; }

.layer-select-country {
  padding: 0; }

.layer-select-city .label-wrapper .custom-checkbox {
  width: 30%;
  min-width: auto;
  margin: 0 10px 10px 0; }

.layer-check {
  margin-top: 20px; }

.layer-multiple-line {
  width: 80%;
  margin: 10px auto;
  display: flex; }
  .layer-multiple-line .icon .iconfont {
    font-size: 40px;
    line-height: 1; }
  .layer-multiple-line .content {
    flex: 1;
    margin-left: 10px; }
    .layer-multiple-line .content h4 {
      font-size: 20px; }
    .layer-multiple-line .content p {
      margin-bottom: 3px; }

.nav-tabs-custom {
  margin: 0px 0 10px;
  position: relative; }
  .nav-tabs-custom .active {
    font-weight: bold;
    border-color: #DDD !important;
    border-bottom-color: transparent !important; }
  .nav-tabs-custom .nav-item {
    margin-right: 10px; }
    .nav-tabs-custom .nav-item .nav-link {
      border-color: #ECEEEF;
      border-bottom-color: #DDD;
      padding-top: 10px;
      padding-bottom: 10px; }
  .nav-tabs-custom .right-handle {
    position: absolute;
    right: 0;
    line-height: 37px; }

.status-panel {
  display: flex; }
  .status-panel.status-alert {
    background-color: #f4f4f4;
    padding: 1.25rem;
    margin-bottom: 15px; }
  .status-panel .icon {
    margin-top: 2px; }
    .status-panel .icon .iconfont {
      font-size: 40px;
      line-height: 1; }
  .status-panel .content {
    align-self: center;
    margin-left: 1.25rem; }
    .status-panel .content ul, .status-panel .content ol {
      margin-bottom: 0;
      padding-left: 16px; }

/*编排*/
.link-back {
  color: #CCC;
  font-size: 18px; }
  .link-back:hover, .link-back:focus {
    color: white;
    text-decoration: none; }

.division span {
  padding-left: 20px;
  margin-left: 20px;
  display: inline-block;
  color: #EEE;
  border-left: 1px solid rgba(255, 255, 255, 0.3); }
  .division span:first-child {
    margin-left: 0;
    border-left: 0; }

.special .nav-row h4 {
  font-size: 16px;
  padding-left: 25px;
  font-weight: bold;
  margin: 17px 0; }

.special .nav-row ul + h4 {
  margin-top: 40px; }

.special .nav-row .nav-side {
  border-top: 1px solid #dbdbdb; }

.special .form-date {
  width: 300px; }

.special .bottom-line {
  border-bottom: 1px solid #DDD;
  padding-bottom: 10px; }

.special .nav-tabs-flex {
  margin-bottom: 10px; }
  .special .nav-tabs-flex .nav-item {
    flex: 1;
    text-align: center;
    font-weight: bold; }
    .special .nav-tabs-flex .nav-item .nav-link {
      background-color: #FFF;
      color: inherit;
      border-radius: 0;
      padding-top: 8px;
      padding-bottom: 9px;
      border-color: #DDD; }
      .special .nav-tabs-flex .nav-item .nav-link:hover {
        /*
                    border-color:transparent;
                     */ }
      .special .nav-tabs-flex .nav-item .nav-link.active {
        background-color: #85c1e9;
        color: white; }
        .special .nav-tabs-flex .nav-item .nav-link.active:hover {
          /*
                        border-color: #DDD #DDD transparent;
                         */
          cursor: default; }

.div-line {
  height: 1px;
  background-color: #E9E9E9;
  border: none;
  margin: 15px 0; }

.plan-wrapper .cell {
  display: inline-block;
  width: 220px;
  height: 120px;
  text-align: center;
  margin: 20px 0px 20px 15px;
  color: white;
  vertical-align: top;
  cursor: pointer;
  opacity: 0.9; }
  .plan-wrapper .cell:hover {
    opacity: 1;
    text-decoration: none; }
  .plan-wrapper .cell .iconfont {
    font-size: 40px;
    display: block;
    margin-top: 20px; }
  .plan-wrapper .cell:nth-child(1) {
    background-color: #3FA19D; }
  .plan-wrapper .cell:nth-child(2) {
    background-color: #55A782; }
  .plan-wrapper .cell:nth-child(3) {
    background-color: #68A5C2; }
  .plan-wrapper .cell:nth-child(4) {
    background-color: #D77A51; }

.nav-row {
  position: relative;
  transition: all 0.5s; }
  .nav-row.hide {
    margin-left: -205px; }
    .nav-row.hide .arrow:before {
      transform: translate(-50%, -50%) rotate(45deg); }
  .nav-row .arrow {
    position: absolute;
    top: 300px;
    right: 0;
    transform: translate(100%, -50%);
    content: "";
    display: block;
    width: 15px;
    height: 50px;
    background-color: #d9d9d9;
    clip-path: polygon(0% 0%, 100% 20%, 100% 80%, 0% 100%);
    z-index: 1; }
    .nav-row .arrow:hover {
      background-color: #2786F3;
      cursor: pointer; }
      .nav-row .arrow:hover:before {
        border-color: white;
        border-left-color: transparent;
        border-bottom-color: transparent; }
    .nav-row .arrow:before {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      top: 50%;
      right: 0;
      transform: translate(0%, -50%) rotate(-135deg);
      z-index: 2;
      border: 2px solid white;
      border-left-color: transparent;
      border-bottom-color: transparent; }

.form-result .col-form-label {
  max-width: 120px; }

.form-result.form-text .form-group {
  margin-bottom: 0; }

.form-result.form-text .text-list {
  width: 800px; }
  .form-result.form-text .text-list .cell {
    width: 24%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 15px; }

.form-result .label-wrapper label {
  min-width: 150px; }

.form-result .form-adjust .form-inline {
  white-space: nowrap;
  width: 700px;
  margin-bottom: 20px; }
  .form-result .form-adjust .form-inline:last-child {
    margin-bottom: 0; }
  .form-result .form-adjust .form-inline .handle {
    margin-left: 50px; }

.form-result .form-adjust .custom-radio {
  min-width: 120px;
  text-align: left;
  justify-content: left; }

.form-result .form-adjust input.form-control {
  width: 80px;
  margin: 0 10px; }

/*
.dl-list{
display: flex;
max-width:1000px;
padding-left: 45px;
margin-bottom: 0;
dt{
width:120px;
}
dd{
flex:1;
display: flex;
flex-wrap:wrap;
.cell{
width: 25%;
margin-bottom: 10px;
}
}
}
 */
.setting-wrapper {
  background-color: #F4F4F4;
  padding: 10px; }
  .setting-wrapper > p {
    border-bottom: 1px solid #DDD;
    padding-bottom: 10px; }
  .setting-wrapper .cell {
    display: inline-block;
    width: 33%;
    text-align: center;
    border-left: 1px solid #DDD;
    padding: 20px 0; }
    .setting-wrapper .cell:first-child {
      border-left: 0; }

/*
$color-type-a: #93221f;
$color-type-b: #087013;
$color-type-c: #276daa;
$color-type-d: #b76c2a;
 */
.type-1 .main-header {
  background-color: #4a3867; }

.type-1 .main-nav {
  background-color: #564178;
  border-bottom-color: #564178; }
  .type-1 .main-nav a {
    color: #EEE; }

.type-2 .main-header {
  background-color: #0d4a25; }

.type-2 .main-nav {
  background-color: #116030; }
  .type-2 .main-nav a {
    color: #EEE; }

.type-3 .main-header {
  background-color: #103f88; }

.type-3 .main-nav {
  background-color: #134A9F; }
  .type-3 .main-nav a {
    color: #EEE; }

.type-4 .main-header {
  background-color: #93283e; }

.type-4 .main-nav {
  background-color: #a72e46; }
  .type-4 .main-nav a {
    color: #EEE; }

.index-layout {
  display: flex;
  margin-top: 20px; }
  .index-layout .table-no-border {
    margin-top: -12px; }
  .index-layout .col-6 > .cell {
    background-color: #F5F8FB;
    padding: 15px 15px; }
  .index-layout .col-6 > .cell + .cell {
    margin-top: 20px; }
  .index-layout .column-double {
    flex: 1; }
  .index-layout .table-list {
    margin-bottom: 3px; }
    .index-layout .table-list a {
      position: relative;
      padding-left: 10px;
      color: #555;
      display: block; }
      .index-layout .table-list a:hover {
        color: #5d98f0; }
      .index-layout .table-list a:before {
        position: absolute;
        content: "";
        width: 6px;
        height: 6px;
        left: 0;
        top: calc(50% - 3px);
        background-color: #CCC; }
    .index-layout .table-list tr:first-child td {
      border-top: none; }
    .index-layout .table-list td {
      height: 35px; }
  .index-layout .sub-title {
    margin-bottom: 0;
    border-bottom: 1px solid #DEDEDE;
    margin-bottom: 15px;
    min-height: auto;
    padding-bottom: 15px; }
  .index-layout .dashboard {
    border: none;
    margin-bottom: 5px;
    display: flex; }
  .index-layout .dashboard > .cell {
    flex: 1;
    background-color: #fff;
    border: none;
    border-top: 10px solid;
    vertical-align: top;
    text-align: center;
    padding: 20px 20px;
    margin: 0; }
    .index-layout .dashboard > .cell + .cell {
      margin-left: 15px;
      margin-right: 0; }
    .index-layout .dashboard > .cell:nth-child(1) {
      border-top-color: #009ED6; }
    .index-layout .dashboard > .cell:nth-child(2) {
      border-top-color: #32AB9F; }
    .index-layout .dashboard > .cell:nth-child(3) {
      border-top-color: #FF9900; }

.help-center-index {
  display: flex; }
  .help-center-index .cell {
    margin-bottom: 5px;
    text-align: center;
    background-color: #fff;
    padding: 20px 0;
    flex: 1; }
    .help-center-index .cell:hover {
      cursor: pointer; }
      .help-center-index .cell:hover p {
        color: #609AED; }
    .help-center-index .cell + .cell {
      margin-left: 20px; }
    .help-center-index .cell .iconfont {
      font-size: 50px;
      color: #609AED; }
    .help-center-index .cell p {
      margin-bottom: 0; }

.ul-plan {
  list-style-type: none;
  padding-left: 0; }
  .ul-plan li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    margin-bottom: 24px; }
  .ul-plan a {
    color: inherit; }
    .ul-plan a:hover {
      color: #639CEA; }

.table-no-border {
  border: none; }
  .table-no-border td, .table-no-border th {
    border: none; }

.login-page {
  background: url(../images/bg.jpg) no-repeat top center;
  background-size: cover; }
  .login-page:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1; }
  .login-page .main-header {
    border: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .login-page .login-footer {
    position: absolute;
    z-index: 2;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
    .login-page .login-footer a {
      color: white; }
    .login-page .login-footer p {
      margin: 5px 0;
      font-size: 12px; }

.login-body {
  background-size: 100% 300px;
  display: flex;
  position: relative; }
  .login-body.login-bg-dark {
    background-size: cover;
    background: url(../images/login-bg.png) no-repeat; }
    .login-body.login-bg-dark .login-footer {
      color: rgba(255, 255, 255, 0.5); }
      .login-body.login-bg-dark .login-footer p {
        color: rgba(255, 255, 255, 0.4); }
      .login-body.login-bg-dark .login-footer a {
        color: inherit; }
        .login-body.login-bg-dark .login-footer a:hover {
          color: rgba(255, 255, 255, 0.8); }

.login-footer {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%; }
  .login-footer a {
    text-decoration: none; }
  .login-footer p {
    margin-bottom: 0;
    font-size: 14px;
    color: #CCC; }

.login-wrapper {
  margin-bottom: 50px; }
  .login-wrapper > h1 {
    color: white;
    font-weight: bold;
    font-size: 36px;
    margin: 0;
    text-align: center;
    padding-top: 100px; }
    .login-wrapper > h1 img {
      width: 230px; }
  .login-wrapper .login-box {
    width: 420px;
    padding: 20px;
    margin: 170px auto 0;
    background-color: #fff;
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2), 5px 5px 15px rgba(0, 0, 0, 0); }
    .login-wrapper .login-box.login-box-2 {
      margin-top: 120px;
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2), 5px 5px 15px rgba(0, 0, 0, 0), 1px 1px 3px rgba(0, 0, 0, 0.2);
      padding: 20px 70px 40px; }
      .login-wrapper .login-box.login-box-2 h1 {
        font-size: 26px;
        font-weight: bold;
        padding: 40px 0 30px;
        text-align: center; }
      .login-wrapper .login-box.login-box-2 .form-group {
        position: relative;
        margin-bottom: 30px; }
        .login-wrapper .login-box.login-box-2 .form-group .help-block {
          position: absolute;
          left: 0;
          top: 100%; }
    .login-wrapper .login-box .form-group {
      width: auto; }
      .login-wrapper .login-box .form-group .help-block {
        visibility: hidden; }
      .login-wrapper .login-box .form-group.has-danger .help-block {
        visibility: visible; }
    .login-wrapper .login-box .form-control {
      height: 40px; }
    .login-wrapper .login-box .img-code {
      width: 120px;
      height: 40px; }
    .login-wrapper .login-box .status {
      height: 120px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .login-wrapper .login-box .status h5 {
        font-size: 18px; }
      .login-wrapper .login-box .status .iconfont {
        font-size: 40px;
        line-height: 1;
        vertical-align: middle; }
      .login-wrapper .login-box .status .icon-success,
      .login-wrapper .login-box .status .icon-i-success {
        color: green; }
      .login-wrapper .login-box .status .icon-failed,
      .login-wrapper .login-box .status .icon-i-error {
        color: #e52f3f; }
      .login-wrapper .login-box .status .icon-alert,
      .login-wrapper .login-box .status .icon-i-alert {
        color: #ffb853; }
    .login-wrapper .login-box .form-inline .form-control {
      flex: 1;
      margin-right: 10px; }
    .login-wrapper .login-box .handle .btn-block {
      height: 40px;
      line-height: 1; }
  .login-wrapper .login-box .header {
    padding: 0px;
    margin: 0 0 40px 0; }
    .login-wrapper .login-box .header h2 {
      font-size: 22px;
      font-weight: bold; }

.city-page {
  background: #Fcfcfc;
  position: relative; }
  .city-page:after {
    content: none; }
  .city-page .city-banner {
    height: 200px;
    color: white;
    text-align: center;
    background: url(../images/bg.jpg) 50% 600px; }
    .city-page .city-banner h2 {
      font-size: 50px;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      font-weight: bold;
      line-height: 200px; }
  .city-page .city-footer {
    color: #666 !important;
    padding: 20px 0 0 0;
    position: relative;
    position: absolute;
    bottom: 0;
    text-shadow: none;
    background-color: #f3f3f3; }
    .city-page .city-footer a {
      color: #666; }
  .city-page .city-wrapper {
    width: 1000px;
    margin: 0px auto 69px;
    padding: 20px 0;
    border: 1px solid #F5f5f5;
    background-color: #fff;
    border-top: 0;
    border-bottom: 0;
    padding: 10px; }
    .city-page .city-wrapper .sub-header {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #DDD; }
      .city-page .city-wrapper .sub-header h2 {
        font-size: 18px;
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 0; }
      .city-page .city-wrapper .sub-header .small {
        font-size: 14px;
        color: #999; }
      .city-page .city-wrapper .sub-header .opt {
        margin-left: auto; }
    .city-page .city-wrapper .list:after {
      content: "";
      display: block;
      clear: both; }
    .city-page .city-wrapper dl {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 45%;
      background-color: #f0f0f0;
      border-radius: 10px;
      padding: 20px 0;
      margin: 15px 2.5%;
      position: relative;
      padding-left: 90px;
      height: 100px;
      float: left; }
      .city-page .city-wrapper dl:after {
        content: "";
        position: absolute;
        width: 10px;
        height: 100%;
        background-color: #3496D9;
        right: 0;
        border-radius: 0 10px 10px 0; }
      .city-page .city-wrapper dl:before {
        content: "";
        position: absolute;
        width: 5px;
        height: 60%;
        background-color: #256d9a;
        left: -5px;
        clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%); }
      .city-page .city-wrapper dl dt {
        position: absolute;
        left: -5px;
        display: inline-block;
        background-color: #3496D9;
        border-radius: 0 30px 30px 0 / 30px;
        padding: 16px 20px;
        color: white;
        box-shadow: 3px 0 5px rgba(0, 0, 0, 0.2); }
      .city-page .city-wrapper dl dd {
        display: inline-block;
        font-size: 16px;
        margin-left: 30px;
        margin-bottom: 0; }

.table-user-info {
  margin-bottom: 20px; }
  .table-user-info td {
    vertical-align: middle;
    height: 42px; }
  .table-user-info .user-avatar-m {
    margin-right: 10px; }

.user-status {
  position: absolute;
  right: 20px;
  font-size: 18px; }

.video-list video {
  width: 260px;
  height: 150px;
  margin: 0 10px 10px 0; }

.table-todo a {
  color: inherit; }
  .table-todo a:hover {
    color: #5D98F0; }

.dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: -2px;
  margin-left: 15px; }
  .dot.dot-not {
    background-color: #C9CACA; }
  .dot.dot-underway {
    background-color: #609AED; }
  .dot.dot-done {
    background-color: #9AC944; }

.timeline {
  border-left: 1px dashed #CCC;
  padding-left: 40px;
  margin-top: -20px;
  padding-top: 20px;
  margin-left: 20px; }
  .timeline .cell {
    margin-bottom: 20px;
    position: relative;
    /*
        &:hover{
        .content{
        display:inherit;
        }
        }
         */ }
    .timeline .cell .head {
      background-color: #f4f4f4;
      display: inline-block;
      width: 380px;
      padding: 30px 0;
      border-radius: 10px;
      position: relative;
      margin-bottom: 10px; }
      .timeline .cell .head:before {
        content: "";
        position: absolute;
        width: 100px;
        height: 1px;
        border-top: 1px dashed #CCC;
        left: -40px;
        top: 50%; }
      .timeline .cell .head:after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        top: 50%;
        left: -40px;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background-color: transparent; }
      .timeline .cell .head .date {
        background-color: #99cb38;
        color: white;
        padding: 5px 30px 5px 10px;
        position: absolute;
        left: -10px;
        top: 50%;
        clip-path: polygon(0% 0%, 85% 0, 100% 50%, 85% 100%, 100% 100%, 0% 150%);
        transform: translateY(-50%); }
        .timeline .cell .head .date:before {
          content: "";
          position: absolute;
          width: 10px;
          height: 8px;
          background-color: #666;
          left: 0;
          top: 100%;
          clip-path: polygon(0% 0%, 100% 0, 100% 100%); }
      .timeline .cell .head .text {
        font-size: 16px;
        padding-left: 120px; }
    .timeline .cell.active .head:after {
      background-color: #5D98F0; }
    .timeline .cell.active .head:before {
      border-color: #5D98F0; }
    .timeline .cell.active .head .date {
      background-color: #5D98F0; }
    .timeline .cell .content {
      border: 1px solid #E9E9E9;
      padding: 10px;
      max-width: 700px; }
      .timeline .cell .content .toolbar.form-inline *:last-child {
        margin-right: 0; }
      .timeline .cell .content > .table {
        margin-bottom: 0; }

.flex-wrapper {
  display: flex;
  align-items: center; }
  .flex-wrapper .handle {
    margin-left: auto; }

.link-refresh {
  font-size: 24px;
  display: block; }
  .link-refresh:focus, .link-refresh:hover {
    text-decoration: none; }

.label-wrapper label {
  min-width: 150px; }

.sub-title {
  border: none; }
  .sub-title h5 span {
    display: inline-block;
    min-width: 9em;
    border-bottom: 3px solid #5D98F0;
    padding-bottom: 10px; }

.layer-single {
  padding: 20px 0 20px 2em; }

.toggle-full-screen {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  color: white;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  border-radius: 5px; }
  .toggle-full-screen:hover {
    background-color: rgba(0, 0, 0, 0.8); }
  .toggle-full-screen .iconfont {
    font-size: 40px; }

.video-player-list-sigle {
  position: relative;
  height: 100%;
  padding: 15px; }
  .video-player-list-sigle .cell {
    background-color: #8F9297;
    position: relative;
    height: 100%; }
    .video-player-list-sigle .cell video {
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      object-fit: fill;
      z-index: 1; }
    .video-player-list-sigle .cell .title {
      position: absolute;
      z-index: 2;
      top: 0;
      width: 100%;
      padding: 0 5px;
      background-color: rgba(255, 255, 255, 0.6); }
    .video-player-list-sigle .cell .info {
      position: absolute;
      z-index: 2;
      bottom: 0;
      width: 100%;
      padding: 0 5px;
      background-color: rgba(255, 255, 255, 0.6);
      display: inline-flex; }
      .video-player-list-sigle .cell .info .position {
        flex: 1; }
      .video-player-list-sigle .cell .info .resolution {
        margin-left: 10px; }

.video-player-list {
  position: relative;
  height: 100%;
  padding: 15px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr); }
  .video-player-list.full-screen {
    grid-gap: unset;
    padding: 0;
    background: #000; }
  .video-player-list .cell {
    background-color: #8F9297;
    position: relative; }
    .video-player-list .cell video {
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      object-fit: fill;
      z-index: 1; }
    .video-player-list .cell .title {
      position: absolute;
      z-index: 2;
      top: 0;
      width: 100%;
      padding: 0 5px;
      background-color: rgba(255, 255, 255, 0.6); }
    .video-player-list .cell .info {
      position: absolute;
      z-index: 2;
      bottom: 0;
      width: 100%;
      padding: 0 5px;
      background-color: rgba(255, 255, 255, 0.6);
      display: inline-flex; }
      .video-player-list .cell .info .position {
        flex: 1; }
      .video-player-list .cell .info .resolution {
        margin-left: 10px; }
    .video-player-list .cell .toolbar {
      position: absolute;
      z-index: 2;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      transition: all 1s;
      margin-bottom: 0; }
      .video-player-list .cell .toolbar .lock {
        display: block;
        cursor: pointer;
        visibility: hidden;
        color: rgba(255, 255, 255, 0.8);
        color: white;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        text-align: center;
        height: 20px;
        width: 20px; }
        .video-player-list .cell .toolbar .lock.active {
          color: #ffeb18;
          visibility: visible; }
    .video-player-list .cell:hover .toolbar .lock {
      visibility: visible; }

.tree-all {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0;
  cursor: pointer;
  padding: 10px 30px;
  background-color: #F5F8FB;
  position: relative;
  border-top: 1px solid #E9E9E9; }
  .tree-all:after {
    content: "";
    position: absolute;
    right: 8px;
    top: 15px;
    width: 12px;
    height: 12px;
    background-size: cover;
    background-image: url(" ../images/plus.svg "); }
  .tree-all:before {
    content: "";
    width: 6px;
    height: 6px;
    background-color: #ccc;
    position: absolute;
    left: 15px;
    top: 16px; }
  .tree-all.active {
    color: black;
    background-color: #EDF5FF; }
    .tree-all.active:before {
      background-color: #6875E1; }
    .tree-all.active:after {
      background-image: url(" ../images/minus.svg "); }
    .tree-all.active:hover {
      background-color: #edf5ff; }

.tree-all ~ .tree-province {
  display: none; }

.tree-all.active ~ .tree-province {
  display: block; }

.tree-province {
  padding-left: 0px;
  list-style-type: none; }
  .tree-province > li {
    padding-left: 0px;
    background-color: #F5F8FB;
    position: relative;
    border-top: 1px solid #E9E9E9; }
    .tree-province > li:after {
      content: "";
      position: absolute;
      right: 8px;
      top: 15px;
      width: 12px;
      height: 12px;
      background-size: cover;
      background-image: url(" ../images/plus.svg "); }
    .tree-province > li:before {
      content: "";
      width: 6px;
      height: 6px;
      background-color: #CCC;
      position: absolute;
      left: 15px;
      top: 16px; }
    .tree-province > li > a {
      color: inherit;
      display: block;
      padding: 10px 30px;
      text-decoration: none; }
    .tree-province > li.active {
      background-color: #edf5ff; }
      .tree-province > li.active:after {
        background-image: url(" ../images/minus.svg "); }
      .tree-province > li.active:hover {
        background-color: #edf5ff; }
      .tree-province > li.active > a {
        color: #000; }
      .tree-province > li.active:before {
        background-color: #6772E4; }
      .tree-province > li.active > .city {
        display: block; }
    .tree-province > li:hover {
      background-color: #fff; }
  .tree-province .city {
    display: none;
    padding-left: 30px;
    list-style-type: none;
    background-color: #fff; }
    .tree-province .city > li {
      position: relative; }
      .tree-province .city > li:after {
        content: "";
        position: absolute;
        right: 8px;
        top: 10px;
        width: 12px;
        height: 12px;
        background-size: cover;
        background-image: url(" ../images/plus.svg "); }
      .tree-province .city > li.active:after {
        background-image: url(" ../images/minus.svg "); }
      .tree-province .city > li > a {
        color: inherit;
        display: block;
        text-decoration: none;
        padding: 5px 0; }
  .tree-province .school {
    padding-left: 0;
    list-style-type: none; }
    .tree-province .school > li {
      position: relative; }
      .tree-province .school > li:after {
        content: "";
        position: absolute;
        right: 8px;
        top: 10px;
        width: 12px;
        height: 12px;
        background-size: cover;
        background-image: url(" ../images/plus.svg "); }
      .tree-province .school > li.active:after {
        background-image: url(" ../images/minus.svg "); }
      .tree-province .school > li > a {
        padding: 5px 0;
        color: inherit;
        display: inline-block; }
        .tree-province .school > li > a .icon-tel {
          color: #666; }
      .status.status-online {
        color: #609AED; }
      .status.status-offline {
        color: #CCC; }
      .tree-province .school > li.active > a {
        color: #609AED; }
  .tree-province .room {
    padding-left: 1em;
    list-style-type: none; }
    .tree-province .room > li > a {
      display: inline-block;
      padding: 5px 0;
      color: inherit; }
    .tree-province .room > li.active > a {
      color: #609AED; }

.tree-province.city-only > li.active:after, .tree-province.city-only > li.active:before {
  content: none; }

.tree-province > li > .city {
  display: none; }
  .tree-province > li > .city > li > .school {
    display: none; }
    .tree-province > li > .city > li > .school > li > .room {
      display: none; }
    .tree-province > li > .city > li > .school > li.active > .room {
      display: block; }
  .tree-province > li > .city > li.active > .school {
    display: block; }

.tree-province > li.active > .city {
  display: block; }

.tree-header {
  font-size: 14px;
  padding: 0 10px; }
  .tree-header span {
    color: #666; }

.nav-row-tree {
  width: 270px !important;
  height: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column; }
  .nav-row-tree.float {
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    /*
        padding-top: 116px;
        */ }
  .nav-row-tree > .content {
    flex: 1;
    overflow: auto; }
  .nav-row-tree .fold-toolbar {
    display: none;
    position: relative;
    z-index: 2;
    text-align: right;
    margin-bottom: -10px;
    background-color: #F9F9F9; }
    .nav-row-tree .fold-toolbar .bar {
      display: inline-block;
      padding: 10px;
      cursor: pointer; }
      .nav-row-tree .fold-toolbar .bar:hover {
        color: #5d98f0; }
      .nav-row-tree .fold-toolbar .bar .inner {
        display: block;
        background-color: #fff;
        border: 1px solid #CCC;
        padding: 5px 10px; }
  .nav-row-tree.folded {
    margin-left: -270px; }
    .nav-row-tree.folded .fold-toolbar .bar {
      background-color: rgba(0, 0, 0, 0.3);
      transform: translateX(100%); }
      .nav-row-tree.folded .fold-toolbar .bar:hover {
        opacity: 1; }
  .nav-row-tree .page-toolbar {
    text-align: right;
    padding: 10px 10px;
    background-color: #fff;
    border-top: 1px solid #E9E9E9; }
    .nav-row-tree .page-toolbar a:hover, .nav-row-tree .page-toolbar a:focus {
      text-decoration: none; }
    .nav-row-tree .page-toolbar > span {
      color: #5d98f0;
      display: inline-block;
      vertical-align: middle;
      font-size: 18px; }
      .nav-row-tree .page-toolbar > span + span {
        margin-left: 10px; }
      .nav-row-tree .page-toolbar > span:hover {
        color: #0275d8;
        cursor: pointer; }
    .nav-row-tree .page-toolbar .full-screen-toggle.enter .icon-full-screen {
      display: block; }
    .nav-row-tree .page-toolbar .full-screen-toggle.enter .icon-restore-screen {
      display: none; }
    .nav-row-tree .page-toolbar .full-screen-toggle .icon-full-screen {
      display: none; }
    .nav-row-tree .page-toolbar .full-screen-toggle .icon-restore-screen {
      display: block; }

.search-form {
  margin: 10px;
  position: relative; }
  .search-form .btn-close {
    margin-right: 25px; }
    .search-form .btn-close .icon-close {
      color: inherit;
      font-size: 18px; }
  .search-form button {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 0;
    color: inherit;
    font-size: 20px; }
    .search-form button:hover, .search-form button:focus {
      color: #0275d8;
      outline: none;
      box-shadow: none;
      text-decoration: none; }

.page-viewer {
  height: 100vh; }

.video-info {
  padding: 10px; }
  .video-info h3 {
    font-size: 16px;
    padding: 5px 0; }
  .video-info .form-group {
    margin-bottom: 15px; }
    .video-info .form-group label {
      margin-bottom: 0;
      color: #000; }
    .video-info .form-group .form-control-static {
      padding: 0; }

.login-v2 {
  background: #3E87FF url(../images/login-v2-illus.svg) no-repeat center;
  background-size: cover;
  position: relative; }
  .login-v2 > .inner {
    position: relative;
    margin: 0 auto; }
  .login-v2 .logo {
    margin-top: 50px;
    text-align: center; }
    .login-v2 .logo img {
      width: 300px; }
  .login-v2 .login-title {
    display: none;
    flex: 1;
    margin-top: 160px;
    margin-right: 3%;
    text-align: right; }
    .login-v2 .login-title > img {
      width: 50%; }
  .login-v2 .login-wrapper {
    display: flex; }
  .login-v2 .login-form {
    margin-left: auto;
    background-color: #fff;
    margin-top: 80px;
    padding: 70px 60px;
    width: 90vw; }
    .login-v2 .login-form label {
      cursor: pointer; }
    .login-v2 .login-form .form-check-input {
      vertical-align: -2px; }
    .login-v2 .login-form .title {
      font-size: 26px;
      font-weight: bold;
      margin-bottom: 30px; }
    .login-v2 .login-form .form-group {
      margin-bottom: 30px; }
      .login-v2 .login-form .form-group .help-block {
        position: absolute; }
    .login-v2 .login-form .form-control {
      border-radius: 0;
      font-size: 16px;
      padding: 15px 15px;
      max-width: unset; }
    .login-v2 .login-form .btn {
      font-size: 18px; }

@media (min-width: 800px) {
  .login-v2 .logo {
    text-align: left; }
  .login-v2 > .inner {
    width: 780px; }
  .login-v2 .login-form {
    width: 420px;
    padding: 60px 40px; } }

@media (min-width: 1000px) {
  .login-v2 > .inner {
    width: 980px; }
  .login-v2 .login-title {
    display: block; }
  .login-v2 .login-form {
    width: 420px;
    padding: 60px 40px; } }

@media (min-width: 1200px) {
  .login-v2 > .inner {
    width: 1100px; }
  .login-v2 .login-form {
    width: 380px;
    padding: 60px 40px; } }

@media (min-width: 1400px) {
  .login-v2 > .inner {
    width: 1300px; }
  .login-v2 .login-form {
    width: 430px;
    padding: 60px 40px; } }

@media (min-width: 1600px) {
  .login-v2 > .inner {
    width: 1500px; }
  .login-v2 .login-form {
    width: 520px;
    padding: 70px 60px; } }

/*
@media (min-width: 1600px){
    .login-v2{
        >.inner{
            width:1500px;
        }
        .login-form{
            width:520px;
            padding:50px 40px;
        }
    }
}
*/
@media (min-height: 700px) {
  .login-v2 .login-form {
    margin-top: 80px; }
  .login-v2 .login-title {
    margin-top: 180px; } }

@media (min-height: 800px) {
  .login-v2 .login-form {
    margin-top: 120px; }
  .login-v2 .login-title {
    margin-top: 220px; } }

.sidebar-row {
  background-color: #fff !important;
  padding: 0px 15px; }
  .sidebar-row .cell {
    border: 1px solid #CCC;
    width: 90%;
    margin: 20px auto;
    padding: 10px;
    border-radius: 5px;
    box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.2);
    display: block;
    text-align: center;
    color: inherit;
    text-decoration: none; }
    .sidebar-row .cell:hover {
      background-color: #fefefe; }
      .sidebar-row .cell:hover .number {
        color: #5D98F0; }
    .sidebar-row .cell .number {
      font-size: 32px;
      display: block; }
    .sidebar-row .cell .title {
      display: block;
      padding-bottom: 5px; }

.s-title {
  border-bottom: 1px solid #CCC;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-top: 10px; }

.date-table .cell {
  display: flex;
  background-color: #fcfcfc;
  margin-bottom: 20px;
  padding: 10px; }

.date-table .date-wrapper {
  width: 120px; }

.date-table .table-wrapper {
  flex: 1; }

.date {
  width: 100px;
  text-align: center;
  border-width: 10px 2px 2px 2px;
  border-style: solid;
  border-color: #CCC;
  padding: 20px 0 5px;
  position: relative;
  margin-top: 20px;
  background-color: #fff;
  margin-left: 5px; }
  .date:after {
    content: "";
    position: absolute;
    width: 15px;
    height: 30px;
    background-color: #CCC;
    top: -20px;
    left: 20%; }
  .date:before {
    content: "";
    position: absolute;
    width: 15px;
    height: 30px;
    background-color: #CCC;
    top: -20px;
    right: 20%; }
  .date .day {
    font-size: 32px;
    line-height: 1.2; }

.show-more {
  padding: 10px;
  margin-top: 20px; }

.null-wrapper {
  text-align: center;
  background-color: #FCFCFC;
  min-height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; }
